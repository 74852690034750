import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'babel-polyfill'
// import VueRouter from 'vue-router';
// import router from './router'
// Vue.use(VueRouter);
Vue.use(ElementUI);

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

import rem from "./config/rem";
rem();
Vue.config.productionTip = false

new Vue({
    // router,
    render: h => h(App),
}).$mount('#app')