import axios from 'axios';
import Vue from 'vue'

let isQa = /^(test|qa)-/.test(location.hostname)
let axiosbaseURL = "https://member" + (isQa ? 'test' : '') + ".bujgame.com"
let axiosUrl = axiosbaseURL + "/passport/main.bj"
Vue.prototype.$axiosUrl = axiosUrl
axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = axiosbaseURL;

function requestFN(method, url, data) {
    // 基础请求方法
    let obj = {
        method, // 请求的类型       
        url, // 请求地址
    }
    if (method === 'get') {
        obj.params = data; // url后面带参数 如 https://echarts.baidu.com/examples/a?test="1"
    } else if (method === 'post') {
        obj.data = data; // data 带参数
    }
    // 返回axios的基础方法
    return axios(obj).then(r => {
        return r; // 方法请求的数据
    })
}
export default requestFN;