<template>
  <div class="login">
    <img class="login_logo" src="@/assets/m/img/logo.png" />
    <div class="login_input">
      <div class="login_input_box login_input_box1">
        <el-input
          class="login_input_item login_input_item1"
          maxlength="11"
          v-model="form.account"
          placeholder="请输入手机号"
        >
        </el-input>
      </div>
      <div class="login_input_box login_input_box2">
        <el-input
          class="login_input_item login_input_item2"
          maxlength="16"
          show-password
          v-model="form.password"
          placeholder="请输入密码"
        ></el-input>
      </div>
      <div class="login_input_box login_input_box3">
        <el-input
          class="login_input_item login_input_item3"
          maxlength="4"
          v-model="form.code"
          placeholder="请输入验证码"
        ></el-input>
        <img @click="getImg" class="login_input_img" :src="imgSrc" />
      </div>
    </div>
    <div class="login_registered" @click="registeredClick">注册账号 ></div>
    <div class="login_submit" @click="onSubmit">登<span></span>录</div>
  </div>
</template>

<script>
export default {
  name: "login",
  props: ["imgSrc"],
  data() {
    return {
      form: {
        account: "",
        password: "",
        code: "",
      },
    };
  },
  mounted() {},
  methods: {
    getImg() {
      this.$emit("getImgsrc");
    },
    registeredClick() {
      this.$emit("registeredClick", "1");
    },
    onSubmit() {
      // debugger;
      let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg.test(this.form.account)) {
        this.$message({
          type: " error",
          message: "请输入正确的手机号",
          center: true,
        });
        return;
      }
      if (this.form.password.length > 16 || this.form.password.length < 6) {
        this.$message({
          type: " error",
          message: "请输入6-16位字符的密码",
          center: true,
        });
        return;
      }
      // let regs = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{4,16}$/;
      if (this.form.code.length != 4) {
        this.$message({
          type: " error",
          message: "请输入正确的验证码",
          center: true,
        });
        this.form.code = "";
        this.$emit("getImgsrc");
        return;
      }
      this.$emit("loginClick", this.form);
    },
  },
};
</script>

<style scoped>
@import url("../../../assets/m/fonts/fonts.css");
.login {
  width: 5rem;
  height: 6rem;
  background-color: #e8f2f8;
  border-radius: 8px;
  text-align: center;
  color: #3d4a53;
  position: relative;
  font-size: 0.35rem;
}
.login_logo {
  width: 1.5rem;
  margin-top: 0.5rem;
  margin-left: 0.3rem;
}
.login_input {
  margin-top: 0.3rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  line-height: 0.8rem;
}
.login_input_item >>> .el-input__inner {
  border-radius: 15px;
  height: 0.6rem;
  background-repeat: no-repeat;
  background-position: 0.1rem 0.1rem;
  background-size: 0.38rem;
  font-family: "YSHaoShenTi-2";
  font-size: 0.25rem;
  background-color: #e8f2f8;
  line-height: normal;
}
.login_input_item1 >>> .el-input__inner {
  background-image: url("../../../assets/m/img/user/user.png");
  padding-left: 0.56rem;
}
.login_input_item2 >>> .el-input__inner {
  background-image: url("../../../assets/m/img/user/password.png");
  padding-left: 0.56rem;
}
.login_input_box3 {
  display: flex;
}
.login_input_img {
  width: 1.6rem;
  height: 0.6rem;
  margin-left: 0.1rem;
  margin-top: 0.1rem;
}
.login_registered {
  font-size: 0.25rem;
  margin-top: 0.4rem;
}
.login_submit {
  width: 100%;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 7px;
  background: #3d4a53;
  color: white;
  position: absolute;
  bottom: 0;
}
.login_submit span {
  display: inline-block;
  width: 0.3rem;
}
.el-input__prefix,
.el-input__suffix {
  top: -3px !important;
}
</style>