<template>
  <div class="customerService">
    <div class="customerService_top">
      <!-- <div class="customerService_top_box">
        <img
          class="customerService_top_img"
          src="../assets/m/img/customerService/QrCode.png"
        />
        <p class="customerService_top_font">官方客服QQ公众号</p>
      </div>
    </div>
    <div class="contact_top_contact">
      <div>
        <img src="../assets/m/img/customerService/QQ.png" />
        <p>
          官方客服QQ <br />
          800171023
        </p>
      </div>
      <div>
        <img src="../assets/m/img/customerService/phone.png" />
        <p>
          客服电话<br />
          010-53653505
        </p>
      </div>
      <div>
        <img src="../assets/m/img/customerService/email.png" />
        <p>
          客服邮箱<br />
          GM@bujgame.com
        </p>
      </div> -->
      <div class="center_box">
        <div class="center_box_left center_public">
          <img src="@/assets/m/img/customerService/phone.png" />
          <p class="line"></p>
          <div class="contact">
            <p>官方电话</p>
            <p>010—53653505</p>
          </div>
        </div>
        <div class="center_box_right center_public">
          <img src="@/assets/m/img/customerService/email.png" />
          <p class="line"></p>
          <div class="contact">
            <p>客服邮箱</p>
            <p>GM@bujgame.com</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="foot">
      <div>
        <p>
          <a href="https://beian.miit.gov.cn " target="_blank"
            >备案号：津ICP备19006017号-2</a
          >    联系电话010-53652155
        </p>
        <p>天津群溢科技有限公司版权所有</p>
        <p>
          <a :href="require('../../assets/public/Group-1.jpg')" target="_blank"
            >ICP证:津B2-20190075</a
          >
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("../../assets/m/animation/animation.css");
.customerService {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  box-sizing: border-box;
  padding-top: 15.5vh;
  letter-spacing: 0.3vh;
}
.customerService_top {
  width: 100%;
  height: 34vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2vh;
  background-color: rgba(0, 0, 0, 0.1);
}
.center_box {
  width: 100%;
  height: 100%;
  color: white;
  font-size: 1.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade-in;
  animation-duration: 2s;
  -webkit-animation: fade-in 2s;
}
.center_public {
  width: 20vh;
  height: 20vh;
  margin-left: 2vh;
  margin-right: 2vh;
  position: relative;
  border: white solid 1px;
  border-radius: 50%;
}
.center_public img {
  width: 0.4rem;
  position: absolute;
  bottom: 14vh;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.line {
  width: 20vh;
  height: 2px;
  margin-left: 0vh;
  margin-top: 8vh;
  background: white;
  opacity: 0.4;
}
.contact {
  width: 100%;
  font-size: 0.15rem;
  text-align: centers;
  margin-top: 2vh;
}
.foot {
  width: 100%;
  height: 15%;
  position: absolute;
  bottom: -15%;
  background: white;
  color: #78808d;
  font-size: 1vh;
  text-align: 3vh;
  letter-spacing: 0.1vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.foot a {
  text-decoration: none;
  color: #78808d;
}
</style>