<template>
  <div id="app">
    <index v-if="pc"></index>
    <mindex v-else></mindex>
    <!-- <router-view></router-view> -->
  </div>
</template>

<script>
import index from "./view/pc/index";
import mindex from "./view/m/index";
export default {
  name: "App",
  components: { index, mindex },
  data() {
    return {
      pc: false,
    };
  },
  mounted() {
    if (this._isMobile()) {
      this.pc = false;
    } else {
      this.pc = true;
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style>
@import url("./assets/pc/fonts/fonts.css");
* {
  padding: 0;
  margin: 0;
}
#app {
  font-family: "YSHaoShenTi-2";
}
.el-message {
  border-radius: 50px !important;
  background: white !important;
  font-family: "YSHaoShenTi-2";
  color: #74787b;
}
.el-input__suffix {
  top: -0.02rem !important;
}
</style>
