<template>
  <div class="index">
    <div class="video-container">
      <div :style="fixStyle" class="filter">
        <div class="index_content">
          <img v-if="logoFlag" class="logo" src="@/assets/m/img/logo.png" />
          <div
            class="index_content_flag"
            :class="[active, back]"
            @click="drawerFlag"
          >
            <p class="index_content_flag_p1"></p>
            <p class="index_content_flag_p2"></p>
            <p class="index_content_flag_p3"></p>
          </div>
          <el-drawer
            class="index_content_drawer"
            :visible.sync="drawer"
            :show-close="false"
            :modal="false"
            :size="180"
            :direction="direction"
          >
            <div class="index_content_drawer_menu">
              <p @click="navigationClick(0)">首页</p>
              <p @click="navigationClick(1)">关于我们</p>
              <p @click="navigationClick(2)">客服中心</p>
              <p v-if="lastNavigation" @click="navigationClick(3)">用户中心</p>
            </div>
            <div v-if="login" class="userbox">
              <p>您好，{{ userId }}</p>
              <p @click="logout" class="logoutBtn">退出</p>
            </div>
          </el-drawer>
        </div>
        <div class="router-view">
          <swiper ref="Myswiper" :options="swiperOption">
            <swiper-slide>
              <homePage v-if="current === 0"></homePage>
            </swiper-slide>
            <swiper-slide>
              <aboutUs v-if="current === 1"></aboutUs>
            </swiper-slide>
            <swiper-slide class="customerService">
              <customerService
                v-if="current === 2"
                class="customerService"
              ></customerService>
            </swiper-slide>
            <swiper-slide v-if="lastNavigation">
              <user
                v-if="current === 3"
                @loginCliCk="loginCliCk"
                @navigationClick="navigationClick"
                @myswiperStop="myswiperStop"
                @myswiperOpen="myswiperOpen"
              ></user>
            </swiper-slide>
            <swiper-slide>
              <div class="foot">
                <div>
                  <p>
                    <a href="https://beian.miit.gov.cn " target="_blank"
                      >备案号：津ICP备19006017号-2</a
                    >    联系电话010-53652155
                  </p>
                  <p>天津群溢科技有限公司版权所有</p>
                  <p>
                    <a
                      :href="require('../../assets/public/Group-1.jpg')"
                      target="_blank"
                      >ICP证:津B2-20190075</a
                    >
                  </p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import homePage from "./homePage";
import aboutUs from "./aboutUs";
import customerService from "./customerService";
import user from "./user";
export default {
  name: "index",
  components: { homePage, aboutUs, customerService, user },
  data() {
    const _this = this;
    return {
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          paginationType: "custom",
        },
        slidesPerView: 1,
        spaceBetween: 30,
        autoHeight: true,
        height: window.innerHeight,
        mousewheel: true,
        direction: "vertical",
        slideToClickedSlide: true,
        slidesPerView: "auto",
        on: {
          slideChangeTransitionEnd: function () {
            _this.current = this.activeIndex;
          },
        },
      },
      login: false,
      userId: "",
      lastNavigation: true,
      fixStyle: "",
      drawer: false,
      direction: "rtl",
      current: 0,
      logoFlag: true,
      active: "",
      back: "",
      false: false,
    };
  },
  watch: {
    current(ne) {
      if (ne === 3) {
        this.logoFlag = false;
      } else {
        this.logoFlag = true;
      }
    },
    drawer(ne) {
      if (ne) {
        this.active = "active";
        this.back = "";
      } else {
        this.active = "";
        this.back = "back";
      }
    },
  },
  mounted() {
    this.initial();
    this.loginCliCk();
  },
  methods: {
    loginCliCk() {
      let id = localStorage.getItem("bjAccountId");
      let token = localStorage.getItem("bjToken");
      if (id || token) {
        this.lastNavigation = false;
        this.userId = id;
        this.login = true;
        this.current = 0;
        this.$refs.Myswiper.$swiper.activeIndex = 0;
      }
    },
    logout() {
      this.login = false;
      localStorage.removeItem("bjAccountId");
      localStorage.removeItem("bjToken");
      this.lastNavigation = true;
      this.drawer = this.false;
    },
    navigationClick(id) {
      this.drawer = false;
      this.current = id;
      this.$refs.Myswiper.$swiper.activeIndex = id;
    },
    drawerFlag() {
      this.drawer = !this.drawer;
      if (this.drawer) {
        this.active = "active";
        this.back = "";
      } else {
        this.active = "";
        this.back = "back";
      }
    },
    initial() {
      this.logoFlag = true;
    },
    myswiperStop() {
      this.$refs.Myswiper.$swiper.mousewheel.disable();
    },
    myswiperOpen() {
      this.$refs.Myswiper.$swiper.mousewheel.enable();
    },
  },
};
</script>
<style scoped>
.index {
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-image: url("../../assets/m/video/M_720x1336.gif");
  background-repeat: no-repeat;
  background-position: 0vh 0vh;
}
.index,
.video-container {
  height: 100vh;
  overflow: hidden;
}
.video-container .filter {
  z-index: 1;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
}
.logo {
  width: 12vh;
  position: absolute;
  top: 4vh;
  left: 3.5vh;
}
.index_content_flag {
  position: absolute;
  top: 5.5vh;
  right: 5vh;
  width: 5vh;
  z-index: 3000;
}
.index_content_flag p {
  height: 1px;
  background: #1f436c;
  margin-bottom: 10px;
}
.index_content_flag_p1 {
  width: 45px;
}
.index_content_flag_p2 {
  width: 38px;
}
.index_content_flag_p3 {
  width: 30px;
}
.active .index_content_flag_p1 {
  animation: p1 0.2s linear;
  animation-fill-mode: forwards;
}
@keyframes p1 {
  0% {
    transform: translate(0vh, 0vh) rotate(0deg);
  }
  100% {
    transform: translate(0vh, 1vh) rotate(-45deg);
  }
}
.active .index_content_flag_p3 {
  animation: p3 0.2s linear;
  animation-fill-mode: forwards;
}
@keyframes p3 {
  0% {
    transform: translate(0vh, 0vh) rotate(0deg);
    width: 30px;
  }
  100% {
    transform: translate(0vh, -2vh) rotate(45deg);
    width: 45px;
  }
}
.active .index_content_flag_p2 {
  animation: p2 0.2s linear;
  animation-fill-mode: forwards;
}
@keyframes p2 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.back .index_content_flag_p1 {
  animation: p1back 0.2s linear;
  animation-fill-mode: forwards;
}
@keyframes p1back {
  0% {
    transform: translate(0vh, 1vh) rotate(-45deg);
  }
  100% {
    transform: translate(0vh, 0vh) rotate(0deg);
  }
}
.back .index_content_flag_p3 {
  animation: p3back 0.2s linear;
  animation-fill-mode: forwards;
}
@keyframes p3back {
  0% {
    transform: translate(0vh, -2vh) rotate(45deg);
    width: 45px;
  }
  100% {
    transform: translate(0vh, 0vh) rotate(0deg);
    width: 30px;
  }
}
.back .index_content_flag_p2 {
  animation: p2back 0.2s linear;
  animation-fill-mode: forwards;
}
@keyframes p2back {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.index_content_drawer_menu {
  text-align: right;
  margin-right: 3.5vh;
  display: flex;
  flex-direction: column;
  margin-top: 6vh;
  line-height: 12vh;
  font-size: 2vh;
  letter-spacing: 1vh;
}
.userbox {
  text-align: right;
  position: absolute;
  top: 80vh;
  right: 3.5vh;
  color: #616f83;
  font-size: 1.5vh;
  z-index: 9;
}
.logoutBtn {
  text-align: center;
  text-decoration: underline;
  margin-top: 1vh;
  margin-left: 16.5vh;
  width: 5vh;
  height: 3vh;
  line-height: 3vh;
  border: 1px solid #616f83;
  border-radius: 10px;
}
.router-view {
  width: 100%;
  height: 100%;
}
.video-container .filter {
  z-index: 1;
  position: absolute;
  width: 100%;
}
a {
  text-decoration: none;
  color: #3d4b61;
}
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: 100vh !important;
}
.swiper-container .swiper-slide {
  height: 100% !important;
  /* background: white; */
}
.swiper-container .swiper-slide:last-child {
  height: 15% !important;
  /* background: white; */
}
.foot {
  width: 100%;
  height: 100%;
  background: white;
  color: #78808d;
  font-size: 1vh;
  text-align: 3vh;
  letter-spacing: 0.1vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.foot div {
  text-align: center;
}
.foot a {
  text-decoration: none;
  color: #78808d;
}
</style>