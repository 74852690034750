<template>
  <div class="userAgreement">
    <el-card class="box-card">
      <div class="title">不鲸用户协议</div>
      <div class="center">
        <p>
          本用户协议系由用户与天津群溢技有限公司及其关联企业（以下简称&ldquo;不鲸游戏&rdquo;）之间就不鲸游戏提供的网络游戏平台服务所订立的权利义务规范。
        </p>
        <p>
          本用户协议所附各类规则或补充条款，为本用户协议不可分割的一部分，与本用户协议具有同等法律效力。本用户协议是用户接受不鲸游戏所提供的网络游戏产品和服务时适用的通用条款。因此，请您在注册使用不鲸游戏网络游戏平台服务前或接受不鲸游戏的网络游戏产品和服务之前，详细地阅读本用户协议的所有内容。
        </p>
        <p>
          用户了解并同意：无论事实上是否认真阅读，只要用户点选&ldquo;同意&rdquo;键并完成注册，或安装、登录、实际使用并接受不鲸游戏网络游戏平台、不鲸游戏产品和服务即视为用户已完全同意并接受本用户协议及不鲸游戏所发布的各项相关游戏规则、服务规则，并愿受其约束。如果发生纠纷，用户不得以未仔细阅读为由进行抗辩。
        </p>
        <p>一、定义</p>
        <p>
          1．不鲸游戏网络游戏平台：是由不鲸游戏为用户提供网络游戏产品和服务，并对用户使用网络游戏产品和服务相关事项进行管理的平台系统，包括但不限于不鲸游戏正在运营或测试中的各类收费或免费的在线游戏及相关的在线服务，以及前述网络游戏及服务所需的相关注册、运行、收费及管理系统。用户注册成为不鲸游戏网络游戏平台用户后，即可安装及使用不鲸游戏提供的各种网络游戏及其它相关系统和服务。不鲸游戏有权根据国家法律法规、不鲸游戏网络游戏平台管理规则以及其它适用的规则，对用户注册、使用游戏平台的行为进行管理。如果用户选择使用不鲸游戏网络游戏平台上的收费网络游戏或其它服务，不鲸游戏将根据用户使用收费网络游戏及其它服务的情况，根据不鲸游戏的收费规则计收费用。
        </p>
        <p>
          2．不鲸游戏网络游戏：是由不鲸游戏自行开发或代理的并向用户提供，而用户需要通过计算机等电子设备与服务器联机进行使用操作的网络互动游戏。不鲸游戏网络游戏包括但不限于：客户端所需的单机计算机程序，游戏所包含的相关的书面文档、图片文件、音影文件、用户手册（说明软件程序的安装与应用方法），以及与不鲸游戏网络游戏相关的说明书、商标、标识以及任何其它的美术品。
        </p>
        <p>
          3．用户：指愿意接受或实际上已经接受不鲸游戏提供的网络游戏产品和服务（包括产品测试阶段）的个人。
        </p>
        <p>二、知识产权和所有权声明</p>
        <p>
          不鲸游戏向用户提供的网络游戏平台、网络游戏及其它服务均基于由不鲸游戏或原始权利人开发并运行的计算机程序。不鲸游戏网络游戏平台、网络游戏及相关的计算机程序的著作权、专利权、商标、商业秘密及其它任何知识产权、所有权和其他相关权益或权利（包括但不限于文字、软件、声音、相片、录像、图表、在广告中的全部内容、为用户提供的商业信息等），均属不鲸游戏或其原始授权人所有。未经不鲸游戏或原始授权人书面同意，任何人或用户均不得擅自复制、再造、下载、重制、传输、修改、编辑、展示、散布、制作衍生著作、销售不鲸游戏网络游戏平台、网络游戏及相关服务等任何信息，不得对不鲸游戏网络游戏平台及网络游戏进行反向工程(Reverse
          Engineering)、反向编译(Decompile)或反汇编(Disassemble)，不得以任何以营利为目的的使用不鲸游戏网络游戏平台、网络游戏，违反者应承担相应的法律责任，且不鲸游戏可立即终止向其提供产品和服务。违反者必须销毁任何已经取得的上述信息、资料、软件或产品。用户在使用不鲸游戏网络游戏平台及不鲸游戏网络游戏过程中所产生并储存于不鲸游戏网络游戏平台中的任何数据信息（包括但不仅限于帐号信息、角色信息、虚拟物品信息、积分信息等数据信息），均属于相关程序在运行过程中产生的衍生数据。不鲸游戏对上述数据信息拥有所有权，用户在按照本用户协议规定正常使用不鲸游戏网络游戏的过程中对属于其用户帐号的数据信息享有有限使用权。在不影响用户正常接受服务的情况下，不鲸游戏有权决定保留或不保留服务器上的全部或部分数据。（详细规定参见《关于维护网络游戏运行环境和公平性的补充条款》）
        </p>
        <p>
          不鲸游戏网络游戏中的各种虚拟物品数据（包括但不限于元宝、金币、银币、游戏币、道具、装备）属于不鲸游戏所提供的服务，其所有权归不鲸游戏所有，用户只能在遵守法律和本用户协议规定及游戏规则的情况下使用。不鲸游戏不认可通过不鲸游戏游戏平台之外的线下交易所产生的交易结果，用户通过不鲸游戏游戏平台之外的线下交易行为所获得的游戏道具将被认定为来源不符合游戏规则。不鲸游戏有权对该交易行为涉及的游戏道具、游戏角色与游戏帐号采取相应的措施，包括但不限于：冻结或者回收游戏道具、对帐号进行倒扣数值、强制离线、封停帐号、删除档案等处理；情节严重的，不鲸游戏保留追究用户法律责任的权利。
        </p>
        <p>三、 许可权利的授予</p>
        <p>
          本用户协议授予用户安装并使用不鲸游戏网络游戏平台的权利。用户在注册成为或实际成为不鲸游戏网络游戏平台用户并获得许可（帐号和密码）后，就可享用不鲸游戏收费或免费网络游戏及其它服务。用户可在自己使用的计算机等电子设备上安装、使用不鲸游戏网络游戏的一份副本，并通过不鲸游戏提供的付费或免费注册途径获得许可（帐号和密码）之后，与不鲸游戏提供或许可的服务器联机进行游戏。用户通过其它任何形式的未经不鲸游戏许可的安装、使用、访问、显示、运行以及转让，都将被视为对用户协议的侵犯。
        </p>
        <p>四、 用户权利义务</p>
        <p>
          1．用户使用不鲸游戏网络游戏平台时，必须遵守中华人民共和国有关法律、法规及本协议的规定。
        </p>
        <p>
          2．用户需自行配备注册和使用网络所需的各项计算机及网络设备，并自行承担上网所需各项费用。
        </p>
        <p>
          3．不鲸游戏网络游戏平台所提供的服务属于商业行为，包含收费内容及免费内容。用户选择收费内容及服务时，有义务根据使用不鲸游戏确定并提供的收费项目及标准支付相应的费用。支付费用方式包括但不限于预付款。如用户通过不鲸游戏提供的用后付款方式使用了不鲸游戏网络游戏而拒不缴纳服务费用，不鲸游戏可终止向该用户提供所有服务并保留要求其偿还服务费用的权利。不鲸游戏将有权决定并随时修改所提供的产品和服务的资费标准和收费方式等资费政策。届时不鲸游戏将在相关页面上作明确提示，对于不鲸游戏的收费产品和服务，用户有权按照不鲸游戏确定的资费政策自行决定购买与否，不鲸游戏可以向不购买者停止提供产品和服务。
        </p>
        <p>
          4．用户了解并同意仅将不鲸游戏网络游戏平台用于个人使用目的，不得将不鲸游戏网络游戏平台、不鲸游戏网络游戏及相关服务用于任何未经授权的商业使用或再销售行为，不得将不鲸游戏网络游戏平台、不鲸游戏网络游戏及相关服务以任何形式用于任何非法的或违背本用户协议的目的，不得利用不鲸游戏网络游戏平台、不鲸游戏网络游戏进行干扰其它网络服务及其它用户正常使用的行为。（详细规定参见《用户须知》、《玩家守则》）
        </p>
        <p>
          5．用户承诺以其真实身份注册成为不鲸游戏的用户，并保证向不鲸游戏提供的个人身份资料信息真实、完整、有效、详尽，包括但不限于姓名、性别、居民身份证或其它有效的合法身份证明文件、个人联系方式等,用户依据法律规定和本用户协议约定对所提供的信息承担相应的法律责任。如果所提供的资料有任何变更，用户应及时到不鲸游戏网络游戏平台进行更新。用户以其真实身份注册成为不鲸游戏用户后，需要修改所提供的个人身份资料信息的，不鲸游戏应当及时、有效地为其提供该项服务。若用户在不鲸游戏留存的个人资料与事实不符，不鲸游戏可以停止向其继续提供产品和服务。用户提交虚假身份证明文件注册使用不鲸游戏网络游戏平台时，不鲸游戏有权拒绝向该用户提供游戏平台的服务，或者有权随时中断或终止向该用户提供本协议项下的游戏服务，而无需向用户或任何第三方承担任何责任；使用他人身份证明文件注册不鲸游戏网络游戏平台时，不鲸游戏仅认可该身份证明文件所对应人员的请求；不鲸游戏有权根据该人员的请求随时中断或终止该身份证明文件所对应的不鲸游戏网络游戏平台帐号，而无需向该帐户实际使用用户或任何第三方承担任何责任。当用户向不鲸游戏主张其拥有某账号时，若该帐号在不鲸游戏的身份记录与用户的身份信息有任何不符，不鲸游戏有权不予以认定账号为该用户所有。由于用户所提供的资料而导致不鲸游戏无法为用户提供或继续提供服务，不鲸游戏将不承担由此产生的任何责任。（详情参见《不鲸游戏用户保护隐私条款》）
        </p>
        <p>
          6．不鲸游戏有权审查用户注册所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障用户账号的安全、有效；用户有义务妥善保管其账号、密码及更改个人资料所需的验证信息，并正确、安全地使用其账号及密码。任何一方未尽上述义务导致账号密码遗失、账号被盗等情形而给用户和他人的民事权利造成损害的，应当承担由此产生的法律责任。
        </p>
        <p>
          7．用户对登陆后所持账号产生的行为依法享有权利和承担责任。为维护自身权益，用户不应将帐号、密码及验证信息泄漏或提供给给第三人知悉，或出借或转让予第三人使用。如因用户自身原因（包括用户计算机感染病毒或木马）而导致帐号、密码或验证信息泄漏，用户应自行承担由此造成的损失，不鲸游戏不承担任何责任。
        </p>
        <p>
          8．若用户发现其账号或密码被他人非法使用或有使用异常的情况的，应及时根据不鲸游戏公布的处理方式通知不鲸游戏予以协助处理，用户应提供与其注册身份信息相一致的个人有效身份信息以证实其合法权益。不鲸游戏核实用户所提供的个人有效身份信息与所注册的身份信息相一致的，应当及时采取措施暂停该用户账号的登录和使用。若不鲸游戏违反上述规定未及时采取措施暂停用户账号的登录和使用，因此而给用户造成损失的，应当承担其相应的法律责任。如果用户没有提供其个人有效身份证件或者用户提供的个人有效身份证件与所注册的身份信息不一致的，不鲸游戏有权拒绝用户的上述请求。除暂停用户帐号使用的情形，不鲸游戏有权拒绝用户以帐号被盗为由提出的其它请求。用户为了维护其合法权益，向不鲸游戏提供与所注册的身份信息相一致的个人有效身份信息时，不鲸游戏应当为用户提供账号注册人证明、原始注册信息等必要的协助和支持，并根据需要向有关行政机关和司法机关提供相关证据信息资料。
        </p>
        <p>
          9．不鲸游戏要求用户提供与其个人身份有关的信息资料时，应当事先以明确而易见的方式向用户公开其隐私权保护政策和个人信息利用政策，并采取必要措施保护用户的个人信息资料的安全。
        </p>
        <p>
          10．不鲸游戏会向用户提供免费或收费的保护账号密码的技术措施，但用户了解并同意
          ，这些工具只能在一定程度上提高账号和密码等个人信息资料的安全，不鲸游戏并不担保用户在使用此类辅助工具后就能保障账号密码的绝对安全，因此不鲸游戏对账号密码等信息的安全问题予以免责。
        </p>
        <p>
          11．用户同意遵守不鲸游戏的各项服务条款，用户应在每次登录游戏前查看不鲸游戏官方网站公布的各项条款和公告等内容，不鲸游戏官方网站上的各项游戏和服务规则也是本协议的有效组成部分，与本协议具有同等法律效力。
        </p>
        <p>
          12．用户有义务遵照本用户协议及相关文件的规定使用不鲸游戏网络游戏平台，服从不鲸游戏的管理，保证持续登陆或使用不鲸游戏网络游戏平台。对于用户持续180天未持续登陆或使用不鲸游戏网络游戏平台的，不鲸游戏有权对该用户帐号采取措施（包括但不限于冻结、注销等）。
        </p>
        <p>
          13.
          用户为未成年人的，账号的注册及游戏内货币的购买及利用，本公司均视为其得到了监护人或法定代理人的同意。
        </p>
        <p>五、 用户个人资料的保护及其使用限制</p>
        <p>
          1．不鲸游戏尊重并保护用户在不鲸游戏网络游戏平台注册并留存的个人资料及信息。非经用户同意，不鲸游戏不会主动公开、编辑、披露或透露任何用户信息。
        </p>
        <p>
          2．仅于下列情况下，不鲸游戏可公开、披露、透露或向第三方提供用户信息，且不承担任何责任：
        </p>
        <p>a 根据法律法规规定必须披露的；</p>
        <p>b 司法部门或其它法律授权的机关依照法定程序要求提供；</p>
        <p>c 在紧急情况下，为维护用户、其他第三方或公众的权益；</p>
        <p>d 为维护不鲸游戏的合法权益；</p>
        <p>
          e
          其他需要公开、编辑或透露用户信息的情况。3．用户在使用不鲸游戏网络游戏平台过程中，可能存在第三方非法截取、窃取或取得用户信息。不鲸游戏将尽量采取合理防御措施防止上述风险的发生，但不鲸游戏不承诺上述风险可以绝对避免，也将不对上述风险的发生承担任何责任。（详细规定参见《不鲸游戏用户保护隐私条款》）
        </p>
        <p>
          4．用户使用Apple设备注册不鲸账号登录相同游戏相同角色进行的充值，即使更换不同的Apple设备亦可持续使用。
        </p>
        <p>六、 用户使用不鲸游戏网络游戏平台的行为限制</p>
        <p>
          1．用户使用不鲸游戏网络游戏平台及不鲸游戏网络游戏，除应遵守法律、法规的规定外，并应遵守本用户协议，以及不鲸游戏发布的其它有关用户行为的相关规定，如用户有违约情形的，不鲸游戏有权依据本用户协议采取相应措施，包括但不限于暂停、终止用户帐号或直接删除帐号或其他中止或终止对用户提供部分或全部服务的行为，不鲸游戏不承担因此给用户带来的任何损失。
        </p>
        <p>
          2．若用户有发布违法信息、严重违背社会公德、以及其他违反法律禁止性规定的行为，不鲸游戏有权立即终止对用户提供服务,
          并保留追究用户因此而须承担的法律责任的权利。
        </p>
        <p>
          3.
          用户不得利用不鲸游戏产品和服务实施不正当行为，否则不鲸游戏有权终止对用户提供服务，这些不正当行为包括但不限于:
        </p>
        <p>a 妨害他人名誉或隐私权；</p>
        <p>
          b
          使用自己名义、匿名或冒用他人或不鲸游戏名义散播诽谤、不实、威胁、不雅、猥亵、违法、攻击性或侵害他人权利的消息或文字，传播色情或其它违反社会公德的言论；
        </p>
        <p>
          c
          利用不鲸游戏网络游戏平台、网络游戏及相关服务程序的错误、漏洞、疏漏从事增加其游戏点数、级别、虚拟物品等有利于自己及他人注册帐号及/或该帐号所拥有虚拟角色的行为；
        </p>
        <p>
          d
          利用不鲸游戏网络游戏平台、网络游戏及相关服务传输或散布计算机病毒、发布广告或贩卖商品、从事不法交易或张贴虚假不实或引人犯罪的信息；
        </p>
        <p>
          e
          利用在使用不鲸游戏网络游戏平台提供的各种收费或免费网络游戏过程中所产生并储存于不鲸游戏网络游戏平台中的全部或部分数据信息，以各种形式为自己及他人牟利；
        </p>
        <p>
          f
          或利用不鲸游戏网络游戏平台、网络游戏及相关服务从事任何违反中华人民共和国法律或其它法令的行为。
        </p>
        <p>
          4．用户提供虚假注册身份信息，或实施违反本用户协议的行为，不鲸游戏有权中止对用户提供全部或部分服务；不鲸游戏采取中止措施应当通知用户并告知中止期间，中止期间应该是合理的，中止期间届满不鲸游戏应当及时恢复对用户的服务。
        </p>
        <p>
          5．用户应就其在使用不鲸游戏所提供的网络服务过程中的行为自行承担相应责任，不鲸游戏提供网络服务供用户自行执行或与其它用户依照用户协议及服务规则共同使用，不鲸游戏就用户自身行为以及因此导致的所有索赔、要求、起诉、诉讼、负债、损失、损害赔偿、成本和费用不承担任何责任。
        </p>
        <p>
          6．用户不可以使用任何第三方软件登录或使用不鲸游戏所提供的产品和服务。用户不可以使用不鲸游戏任何内容来创造或提供相同或类似的网络服务，如仿真服务器、私人服务器、私设服务器等。用户不可以对不鲸游戏服务器采取任何的黑客行为，或是尝试着使不鲸游戏服务器过度负荷。用户不可以利用外挂或其它软件或程序非法修改、生成网络游戏内的各种装备及其它相关信息。用户在拥有合法的帐号下，可以在不鲸游戏的服务器上传使用网络服务所必须的资料，资料的内容不得包括如下内容：
        </p>
        <p>a 侵犯任何第三者的知识产权，版权或公众、私人权利；</p>
        <p>b 违反任何法律、行政法规或善良风俗；</p>
        <p>
          c
          包含任何侮辱或毁谤他人，性骚扰，民族性的，种族歧视的或对未成年人有不良影响的内容；
        </p>
        <p>
          d
          包含病毒、特洛依木马、定时炸弹等可能对不鲸游戏系统造成伤害或影响其稳定性的内容；
        </p>
        <p>
          用户违反上述规定的，不鲸游戏有权终止对用户提供服务,
          并保留索赔和追究法律责任的权利(包括刑事责任)。
        </p>
        <p>
          7.
          任何用户有如下任意一种或多种行为的，不鲸游戏有权视情况不同暂停、终止或取消该用户的账号使用，并保留索赔和追究法律责任的权利(包括刑事责任)：
        </p>
        <p>a 有违反中国的法律、法规；</p>
        <p>b 违反本用户协议及游戏规则的行为；</p>
        <p>c 提供虚假注册信息；</p>
        <p>d 通过不正当手段使用不鲸游戏网络服务；</p>
        <p>e 为任何非法目的而使用不鲸游戏产品和服务；</p>
        <p>f 有损害其他用户的行为；</p>
        <p>g 滥用所享有的用户权利；</p>
        <p>h 违背社会风俗和社会道德的行为；</p>
        <p>i 煽动民族仇恨、民族歧视，破坏民族团结的行为；</p>
        <p>j 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的行为；</p>
        <p>k 损害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的行为；</p>
        <p>l 在公众场合发布有关不鲸游戏及相关服务的负面宣传；</p>
        <p>
          m
          不得通过第三方软件或在任何第三方软件的辅助下使用不鲸游戏的产品或接受不鲸游戏的服务，不得采取任何破坏游戏规则和违反游戏公平性的行为；
        </p>
        <p>
          n
          不得以违反游戏使用规则等非正常方式获得游戏中的虚拟物品（包括但不限于虚拟货币、虚拟道具和装备）；
        </p>
        <p>o 不得在未经允许下利用不鲸游戏所提供的产品和服务在现实中盈利；</p>
        <p>p 不得利用不鲸游戏服务进行其他不利于不鲸游戏的行为。</p>
        <p>
          8．为了保证不鲸游戏服务器空间不被浪费，保证不鲸游戏所提供服务的质量，用户应当保证持续登陆或使用不鲸游戏游戏。如果用户注册帐号后，出现连续180天未登陆或使用不鲸游戏网络游戏的情况，不鲸游戏有权根据情况自行决定对该用户帐号采取处理措施。不鲸游戏的处理措施包括但不限于，清除该用户在不鲸游戏网络游戏平台及数据库中的全部记录（包括但不限于角色信息、等级物品、点卡、积分信息等）、冻结或删除该用户帐号。用户帐号被删除时，其帐号所有资料及记录将被同时删除。对于连续180天未登陆或使用不鲸游戏游戏而导致帐号被冻结或删除的用户，不鲸游戏将不予任何赔偿或补偿。
        </p>
        <p>
          9．如因用户违反本用户协议的规定导致其注册帐号被删除的，用户无权要求不鲸游戏返还其帐号中剩余点数对应的人民币价值。
        </p>
        <p>七、 服务的停止和变更</p>
        <p>
          1．用户了解并同意，不鲸游戏将定期、不定期或根据不鲸游戏的判断随时对不鲸游戏网络游戏平台及网络游戏进行停机维护，不鲸游戏网络游戏平台运行过程中存在由于可能发生的技术问题、第三方原因或不可抗力等而导致不鲸游戏所提供的服务暂时中断。不鲸游戏将尽其全力避免游戏平台服务的中断或将中断时间限制在最短时间内。用户承诺不就此追究不鲸游戏的法律责任。
        </p>
        <p>
          2．用户了解并同意，在下列情况发生时，不鲸游戏有权决定停止或变更提供给用户的不鲸游戏网络游戏平台、网络游戏及相关服务：
        </p>
        <p>a 国家法律法规或政策的要求；</p>
        <p>b 有关政府机构的要求；</p>
        <p>c 由于业务调整，不鲸游戏认为需要终止或变更服务的。</p>
        <p>
          由于上述原因停止或变更不鲸游戏网络游戏平台、网络游戏及相关服务时，不鲸游戏将提出相应的后续问题处理方案，用户同意按照不鲸游戏处理案提出的原则和方案解决相关问题。
        </p>
        <p>
          3．任何时候（包括但不限于用户正在注册帐号或已经在游戏中运行等），如不鲸游戏发现用户在游戏中注册或使用的帐号、角色、帮会等一切自定义名称与其他用户相同或者相似而导致无法识别，或者含有不当词语，不鲸游戏有权要求用户修改上述名称。如用户不予修改，不鲸游戏有权在用户自定义的名称后加注识别符号予以区别以确保游戏正常运行（例如用户希望或正在使用的角色名称为&ldquo;潇洒
          &rdquo;，但在同一组服务器中同样存在另外一个用户角色名为&ldquo;潇洒&rdquo;，则在用户不愿意修改名称的情况下，不鲸游戏有权在用户名称后加注识别符号后成为&ldquo;潇洒1&rdquo;、&ldquo;潇洒2&rdquo;等），用户保证无条件同意上述修改。
        </p>
        <p>
          4．任何时候，不鲸游戏均可无需事先通知用户而对不鲸游戏网络游戏平台、网络游戏的内容、区域或所用服务器组进行合并、分拆或其他不鲸游戏认为必要的调整。用户不得以此向不鲸游戏要求任何补偿或赔偿。由于游戏区域或服务器组合并导致用户在游戏中的自定义名称与其他用户相同或者相似而导致无法识别时，不鲸游戏有权根据本用户协议七.3条款的规定予以处理。
        </p>
        <p>
          5．用户了解并同意，当不鲸游戏在服务器数据出现异常（包括程序Bug导致的数据异常）时，可以将该服务器的数据还原到一定时点，不鲸游戏对此免责。不鲸游戏可以酌情对用户予以一定补偿。
        </p>
        <p>
          6．同一用户注册的账号中有一个账号或多个帐号出现违反本协议约定的行为，不鲸游戏有权根据实际情况，中止、终止对该用户名下部分或所有账号的服务，或取消该用户名下部分或所有账号。
        </p>
        <p>八、 测试服务器和体验服务器</p>
        <p>
          测试服务器（测试服、测服）是指不鲸游戏在其公布的测试阶段（包括但不限于封测、内测和公测）中向用户提供网络游戏和服务的服务器。体验服务器（体验服、体服）是指不鲸游戏在某些游戏中推出的测试最新版本游戏系统、游戏功能及游戏玩法等游戏内容的稳定性的服务器，用户可以在体验服务器中体验不鲸游戏最新的网络游戏和服务。
        </p>
        <p>
          用户了解并同意，一旦登录不鲸游戏测试服或体验服，或已经实际接受不鲸游戏测试服或体验服的游戏产品和服务，即表示已理解并接受以下内容：
        </p>
        <p>
          a
          测试服或体验服提供的产品和服务内容为非正式版本，若与正式产品和服务有所不同属正常现象，不鲸游戏有权不对登录测试服和体验服的用户提供不鲸游戏官方网站上公布的各项客户服务等内容;
        </p>
        <p>
          b
          测试服和体验服自有的不稳定属性决定了可能存在程序不稳定或数据丢失等异常情况，对登陆或接受测试服或体验服产品和服务的用户在测试服和体验服进行游戏时产生的一切异常情况或遭受的一切损失，不鲸游戏将不予承担任何责任;
        </p>
        <p>
          c
          不鲸游戏有权不保留用户在测试服以及体验服内的一切资料，不鲸游戏将随时或不定期的对测试服或体验服进行维护，并根据需要对测试服和体验服的数据进行全部或部分的删除（包括但不限于用户账户内的角色、经验值、道具等信息）或合理使用上述数据。
        </p>
        <p>九、 风险承担</p>
        <p>
          用户同意使用不鲸游戏网络游戏平台、不鲸游戏网络游戏及相关服务，是出于用户个人意愿，并愿承担因自身过错而产生的任何风险，包括但不限于其因不当执行不鲸游戏网络游戏平台、不鲸游戏网络游戏及相关服务或自行由不鲸游戏网络游戏的官方网站下载游戏或资料图片而导致用户或其所使用的计算机系统损害，或发生任何资料的流失等。
        </p>
        <p>
          不鲸游戏对于任何包含、经由或链接、下载或从任何通过不鲸游戏服务所获得的任何内容、信息或广告，不声明或保证其正确性或可靠性；并且对于用户经不鲸游戏发布的广告、展示而购买、取得的任何产品、信息或资料，不鲸游戏不承担保证责任。
        </p>
        <p>十、 免责条款</p>
        <p>
          不鲸游戏就不鲸游戏网络游戏平台、不鲸游戏网络游戏及相关服务，不承担任何明示或默示的担保责任，亦不保证不鲸游戏网络游戏平台及不鲸游戏网络游戏的稳定、安全、无误及不中断。不鲸游戏对不鲸游戏网络游戏平台、不鲸游戏网络游戏及相关服务不保证不会出现任何程序瑕疵或BUG。不鲸游戏将尽其全力采取现有技术手段尽量避免上述事项的发生，但不鲸游戏不保证上述事项将全部避免，并不对由此可能产生的问题承担任何赔偿责任。
        </p>
        <p>十一、 与充值相关的不诚信行为处理规则</p>
        <p>
          诚实信用也是用户在充值相关行为中应遵循的基本原则。用户使用不鲸游戏服务成功为不鲸游戏充值获得虚拟物品使用权或增值服务（以下统称&ldquo;充值收益&rdquo;）后，如对不鲸游戏服务有任何误会、不满意、建议或其他诉求的，可随时通过客服通知不鲸游戏。用户未选择该等方式而是另行进行退款操作的，应立即通过客服专区或客服电话通知不鲸游戏扣除相应的充值收益；如用户进行退款操作后仍占有充值服务的属不诚信行为，不鲸游戏将予以严厉打击和处罚，包括但不限于如下一项或几项：警告、倒扣数值、冻结或回收游戏虚拟物品、暂时冻结玩法、永久冻结玩法、强制离线、封停账号、删除档案及采取其他技术措施防止用户从事该等行为；情节严重的，不鲸游戏保留追究用户法律责任（包括但不限于民事责任、刑事责任）的权利。
        </p>
        <p>十二、 赔偿责任的排除及限制</p>
        <p>
          不鲸游戏对于用户不当使用不鲸游戏网络游戏平台、网络游戏及相关服务或在上述情况下所产生的任何直接、间接、衍生、特殊或隐性的损害或利益损失不承担任何赔偿责任。如果依法无法完全排除损害赔偿责任时，用户不得以其帐号或帐号内虚拟物品或积分情况作为赔偿标准，不鲸游戏的赔偿责任仅以不鲸游戏网络游戏平台所记录的用户就使用不鲸游戏网络游戏平台、网络游戏及相关服务而向不鲸游戏支付的有效价款为限。
        </p>
        <p>十三、 链接及广告信息</p>
        <p>
          不鲸游戏在不鲸游戏网络游戏平台相关页面或不鲸游戏网络游戏官方网站上所提供的所有链接，可能链接到其它个人、公司或组织的网站。提供该等网站的目的，是便利用户自行搜寻或取得信息。不鲸游戏对于被链接的个人、公司或组织的网站所提供的产品、服务或信息，不担保其真实性、完整性、实时性或可信度；这些个人、公司或组织与不鲸游戏间亦不存在任何雇用、委任、代理、合伙或其它类似的关系。
        </p>
        <p>
          不鲸游戏在所提供的不鲸游戏网络游戏平台及相关的网络服务过程中，可能会在有关位置刊登商业广告或其它促销活动信息。这些广告或促销活动信息的内容由广告商、商品或服务提供者提供，并承担相应责任。不鲸游戏仅为广告信息发布的媒介。用户通过不鲸游戏或其所链接的网站购买的商品或服务，其交易行为仅存于用户与该商品或服务提供者之间，与不鲸游戏无关，不鲸游戏不就用户与该商品或服务提供者之间所产生的交易行为承担任何法律责任。
        </p>
        <p>十四、惩罚的类型与定义</p>
        <p>&nbsp;</p>
        <p>
          良好的秩序是一切美好事物的基础。不鲸游戏公司在游戏中所作出的一切处罚，均是为了维护游戏世界的和谐与公平。若您违反本协议的规定，除了根据其他条款规定受到相应处罚外，还可能会遭受以下一项或几项惩罚:
        </p>
        <p>
          (1)警告：警告仅仅是针对违反游戏规则的玩家做出的教育导向，它是用于正常管理游戏运行的一种方式。
        </p>
        <p>
          (2)禁言：关闭违规玩家的部分或全部聊天频道，强制暂停玩家用户帐号的线上对话功能，使玩家用户帐号无法与其他玩家对话，直到此次处罚到期或是取消。
        </p>
        <p>
          (3)暂时冻结玩法：将违规玩家的游戏角色进行限制，限制其局部游戏操作，直到此次处罚到期或是取消。
        </p>
        <p>
          (4)永久冻结玩法：将违规玩家的游戏角色进行限制，限制其局部游戏操作，且永久不予以解除或释放。
        </p>
        <p>
          (5)暂时禁止登录：违规玩家的用户帐号将在一定的时间内暂时无法登录使用不鲸游戏公司提供的某个或者某几个游戏，直到此次处罚到期或是取消。
        </p>
        <p>
          (6)永久禁止登录：违规玩家的用户帐号将永久无法登录使用不鲸游戏服务。
        </p>
        <p>
          (7)强制离线：强制让违规玩家离开当前游戏，结束玩家当前游戏程序的执行。
        </p>
        <p>
          (8)封停账号：暂停或永久停止违规玩家游戏帐号登录不鲸游戏服务的权利。
        </p>
        <p>
          (9)删除档案：将违规玩家在游戏世界中的人物档案删除，不让该人物再出现在游戏世界。
        </p>
        <p>
          (10)承担法律责任：玩家的不当行为对他人或者不鲸游戏公司造成损害或者与现行法律规定相违背的，违规玩家要依法承担相应的民事、行政或刑事责任。
        </p>
        <p>(11)解散组织：解散玩家成立的包括但不限于帮派、工会、势力等组织。</p>
        <p>
          (12)修改昵称：对于玩家包括但不限于人物昵称、帮派名称、摊位名称、商店名称、宠物名称、召唤兽昵称、空间名称等可以由玩家自主命名的昵称或名称进行强制修改。
        </p>
        <p>
          (13)暂时限制游戏行为：违规玩家的用户帐号将在一定的时间内被限制使用部分游戏服务。
        </p>
        <p>
          (14)撤销交易：撤销违规交易、将交易双方付出的游戏虚拟物品予以还原。
        </p>
        <p>
          (15)扣除违规交易所得：扣除违规交易中，违规一方或违规双方所获得的游戏虚拟物品。
        </p>
        <p>
          (16)交易功能限制：暂时冻结违规交易中，违规一方或违规双方所获得的游戏虚拟物品，并持续考察其在指定期限内的游戏内行为。如指定期限内其无再次违规的，到期自动解冻相应的游戏虚拟物品；如指定期限内其再次违规的，在不鲸游戏公司认定其违规行为存在时扣除其被冻结的游戏虚拟物品。
        </p>
        <p>
          (17)冻结游戏虚拟物品：对违规玩家限定其游戏角色的游戏虚拟物品的相关功能，包括但不限于物品的使用、交易、给予等。
        </p>
        <p>
          (18)收回游戏虚拟物品：对于玩家因欺诈或其他违规行为而获取的游戏虚拟物品，包括但不限于游戏虚拟物品进行收回。
        </p>
        <p>
          （19）其他处罚措施：包括但不限于没收不正当利益（如玩家通过不正当途径获得的游戏虚拟物品）、倒扣数值（包括但不限于经验值等）、删除论坛帖子、屏蔽论坛账号、暂时中断服务、要求承担损害赔偿责任等。
        </p>
        <p>十五、 用户协议及相关服务规则的修改</p>
        <p>
          不鲸游戏有权根据需要，不定时地制定、修改、增删（以下统称&ldquo;修订&rdquo;）本用户协议及相关服务规则（包括玩家守则）。对本用户协议及相关服务规则（包括玩家守则）的所有修订，不鲸游戏将在不鲸游戏网络游戏平台、不鲸游戏网络游戏及相关网站公告栏或其它不鲸游戏认为适当的位置发布，而不另行对用户进行个别通知。所有经修订的用户协议及相关服务规则（包括玩家守则）自公布之日起自动生效，并代替原用户协议及原服务规则、玩家守则的相应内容。用户应该在每次登录不鲸游戏游戏前查询包括不鲸游戏官方网站在内的上述位置的相关公告，以了解本用户协议及相关服务规则（包括玩家守则）的变化。如用户不同意该修订的，应当卸载不鲸游戏软件、终止本协议并停止使用不鲸游戏服务；若用户选择停止使用不鲸游戏的产品和服务，则不鲸游戏不再对该用户承担任何义务和责任。如果在不鲸游戏修改本用户协议或相关服务规则（包括玩家守则）后，用户继续使用不鲸游戏网络游戏平台、不鲸游戏网络游戏及其服务的行为将被视为对用户协议或服务规则（包括玩家守则）修订的同意和接受。由于用户在用户协议及相关服务规则（包括玩家守则）修订后因未熟悉公告规定而引起的损失，不鲸游戏将不会承担任何责任。
        </p>
        <p>十六、 通知和送达</p>
        <p>
          a
          不鲸游戏向用户发出的通知，可以采用电子邮件或页面公告的方式，该等通知于发送或公布之日视为已送达用户;
        </p>
        <p>
          b
          用户向不鲸游戏发出的通知，可以采用电子邮件的方式，该等通知自不鲸游戏收到该等邮件之日起视为送达。
        </p>
        <p>十七、 不可抗力</p>
        <p>
          如发生不可抗力事件而造成一方不能履行本用户协议，可免除该方违约责任，由各方承担各自相应的风险，但由于该方未及时书面通知对方或未采取防止损失扩大的措施所造成的损失，由该方承担。
        </p>
        <p>十八、 适用法律和管辖</p>
        <p>
          本用户协议的生效、履行、解释等有关一切争议的解决均应适用中华人民共和国法律，双方在本协议执行过程中如果有纠纷，应以友好协商的方式解决；如协商不成，任何一方可以将争议提交天津市滨海新区人民法院解决。
        </p>
        <p>十九、 其他</p>
        <p>
          1.《不鲸游戏用户保护隐私条款》、《关于维护网络游戏运行环境和公平性的补充条款》、《玩家守则》、《用户须知》属于本用户协议不可分割的一部分，与本用户协议具有同等法律效力。
        </p>
        <p>
          2.若本用户协议中任何内容无论因何种原因完全或部分无效或不具有执行力，本用户协议的其余内容仍应有效并且约束协议各方。
        </p>
        <p>&nbsp;</p>
        <p align="left">&nbsp;</p>
        <p>不鲸游戏用户保护隐私条款</p>
        <p>
          尊重用户个人隐私是不鲸游戏（定义详见《不鲸游戏用户协议》）的一项基本原则，由于网络的本身特征，不鲸游戏将无可避免地与个人用户产生直接或间接的互动关系，故本条款旨在说明不鲸游戏和用户个人之间就有关用户信息的收集、使用和保护政策的约定：
        </p>
        <p>一、 定义</p>
        <p>
          &ldquo;隐私&rdquo;是指用户为获得不鲸游戏产品和服务而提供给不鲸游戏的个人资料，包括但不限于真实姓名、身份证件号码、手机号码、电子邮箱、密码保护提示问题及标准答案。用户帐号下的角色、虚拟道具等信息及使用游戏时产生的数据属不鲸游戏所有，不属于本条款所指的隐私。
        </p>
        <p>二、会员建立</p>
        <p>
          当您访问不鲸游戏网络游戏平台时，会有建立不鲸游戏网络游戏会员的选项。如果您选择建立不鲸游戏网络游戏会员，在您的同意及确认下，不鲸游戏将要求您提供一些个人资料，包括您的电子邮件等信息来建立新用户，不鲸游戏会将您选择要提供的信息储存在一个安全的数据库中（以下简称为&ldquo;基本资料&rdquo;）。
        </p>
        <p>三、登入</p>
        <p>
          当您访问一个不鲸游戏自行开发或者代理的不鲸游戏网络游戏及其它收费服务站台，而且选择登入该站台时，您的基本资料会安全地传送至该站台，如此可以节省您在网站之间移动时需要重复输入信息的时间。不鲸游戏使用先进的加密技术尽可能保障您基本资料的安全及隐私。
        </p>
        <p>四、存取您的基本资料</p>
        <p>
          您可以在任何时候通过使用不鲸游戏会员服务，新增或更新您的基本资料。
        </p>
        <p>
          如果您使用的是公用的计算机，每当您结束浏览时，您有义务注销不鲸游戏网络游戏会员，以确保其它人无法存取您的基本资料。
        </p>
        <p>五、隐私权原则</p>
        <p>不鲸游戏采取下列几项原则尽可能地保护您的隐私权：</p>
        <p>原则一：个人资料确认</p>
        <p>
          当你同意建立不鲸游戏网络游戏会员的时候，会要求你提供一些个人资料，这些个人资料包括：
        </p>
        <p>
          1.个人识别资料：如姓名、性别、年龄、出生日期、身份证号码(或护照号码)、电话、通信地址、住址、电子邮件地址等情况。
        </p>
        <p>2.个人背景：职业、教育程度、收入状况、婚姻、家庭状况。</p>
        <p>
          3.验证信息：密码提示问题、个人资料验证信息等用于保护用户个人资料不被冒用、盗用的信息。
        </p>
        <p>原则二：使用非个人化信息</p>
        <p>
          1.不鲸游戏将通过您的IP地址来收集非个人化的信息，例如您的浏览器性质、操作系统种类、给您提供接入服务的ISP域名等，以优化在您计算机屏幕上显示的页面。通过收集上述信息，不鲸游戏也进行客流量统计，从而改进网站的管理和服务，或进行网络行为的调查或研究。
        </p>
        <p>
          2.在您使用我们服务过程中，为向您提供浏览、搜索等基本功能，并识别帐号异常状态、了解产品适配性、保障不鲸游戏服务的网络及运营安全，以维护前述基本功能的正常运行，我们可能会直接或者间接地收集、存储关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：
        </p>
        <p>
          （1）日志信息：当您使用我们的服务时，我们可能会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如:您的登录帐号、IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页浏览记录、Push打开记录、停留时长、刷新记录、发布记录、关注、订阅、收藏及分享。
        </p>
        <p>
          （2）设备信息：我们可能会接收并记录您所使用的设备相关信息。例如：设备机型、操作系统及版本、客户端版本、设备分辨率、包名、设备标识符（Android
          ID/SN/IDFA/IDFV）、软硬件特征信息、设备所在位置相关信息（例如IP
          地址）。为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限，我们收集这些信息是为了向您提供我们基本服务和基础功能，如您拒绝提供上述权限将可能导致您无法使用不鲸游戏服务。
        </p>
        <p>
          请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。这些信息在结合使用期间，将作为您的个人信息按照本政策处理与保护。
        </p>
        <p>原则三：基本资料保护及处理</p>
        <p>
          1.不鲸游戏将对您所提供的资料在现有条件下进行严格的管理及保护，不鲸游戏将使用现有相应的技术，尽力防止您的个人资料丢失、被盗用或遭篡改。
        </p>
        <p>
          2.不鲸游戏将视情况在必要时委托专门的技术人员代为对该类资料进行电脑处理，以符合专业分工时代的需求。
        </p>
        <p>原则四：安全的资料储存与转送</p>
        <p>
          不鲸游戏使用的服务器提供控制设备保护，您的基本资料储存在该服务器。当您要求将基本资料传送到某个支持网站时，不鲸游戏会加密您的基本资料，然后使用先进的加密技术将基本资料传送到该网站。
        </p>
        <p>原则五：限制利用原则</p>
        <p>
          不鲸游戏只有在符合下列条件之一，方对收集的基本资料进行必要范围以外利用：
        </p>
        <p>1.已取得您的书面同意。</p>
        <p>2.为免除您在生命、身体或财产方面之急迫危险。</p>
        <p>3.为防止他人权益之重大危害。</p>
        <p>4.为维护不鲸游戏的合法权益。</p>
        <p>5.为增进公共利益，且无害于您的重大利益。</p>
        <p>原则六：个人资料的披露</p>
        <p>
          未经用户许可不鲸游戏不得向任何第三方披露、提供、公开或共享用户注册资料中的姓名、个人有效身份证件号码、联系方式、家庭住址等个人身份信息，但下列情况除外：
        </p>
        <p>1.用户或用户监护人授权不鲸游戏披露的；</p>
        <p>2.有关法律要求不鲸游戏披露的；</p>
        <p>3.司法机关或行政机关基于法定程序要求不鲸游戏提供的；</p>
        <p>4.不鲸游戏为了维护自己合法权益而向用户提起诉讼或者仲裁时；</p>
        <p>5.应用户监护人的合法要求而提供用户个人身份信息时；</p>
        <p>6.在紧急情况下为保护社会公共利益需要而披露用户的个人资料的。</p>
        <p>在上述除外情况下不鲸游戏披露个人资料，不鲸游戏不承担责任。</p>
        <p>原则七：有关免责</p>
        <p>除上述原则六所规定的属免责以外，下列情况不鲸游戏得以免责：</p>
        <p>
          1.不鲸游戏将努力采取商业上的合理方式以保护用户的隐私安全，并使用商业上合理的安全技术措施来保护用户隐私不被未经授权的访问、使用或泄漏。但不鲸游戏仍然不能保证现有的安全技术措施能使用户的个人资料等信息不受任何形式的损失。在不鲸游戏已采取商业合理方式保护用户隐私安全且不鲸游戏无主观故意情况下，对用户个人资料的泄漏不鲸游戏得以免责。
        </p>
        <p>
          2.由于您将用户密码告知他人或未保管好自己的密码或与他人共享注册帐户或任何其它非不鲸游戏的过错，由此导致的任何个人资料泄露。
        </p>
        <p>
          3.任何由于计算机问题、黑客攻击、计算机病毒侵入或发作、电信部门技术调整导致之影响、因政府管制而造成的暂时性关闭、由于不可抗力原因(包括火灾、水灾、雷击、地震、洪水、台风、龙卷风、火山爆发、瘟疫和传染病流行、罢工、战争或暴力行为或类似事件等)造成不鲸游戏不能正常发布以及其它影响网络正常经营之不可抗力及其它任何非因不鲸游戏过错而造成的个人资料泄露、丢失、被盗用或被篡改等。
        </p>
        <p>
          4.由于非不鲸游戏原因，与不鲸游戏链接的其它网站所造成的个人资料泄露及由此而导致的任何法律争议和后果。
        </p>
        <p>
          5.任何个人用户，包括未成年人用户向不鲸游戏提供错误、不完整、不实信息等造成不能正常使用不鲸游戏服务或遭受任何其它损失。
        </p>
        <p>
          6.为了网站的正常运行，需要定期或不定期地对网站进行停机维护，因此类情况而造成的正常服务中断等。
        </p>
        <p>
          本条款为《不鲸游戏用户协议》的补充，为《不鲸游戏用户协议》不可分割的一部分，与其具有同等法律效力。
        </p>
        <p>关于维护网络游戏运行环境和公平性的补充条款</p>
        <p>一、 前言</p>
        <p>
          1.本条款是《不鲸游戏用户协议》的补充性内容，为《不鲸游戏用户协议》不可分割的一部分，与其具有同等法律效力。
        </p>
        <p>
          2.本条款针对侵害网络游戏运行环境和公平性的行为，明确其应承担的法律责任。
        </p>
        <p>
          3.制订本条款的目的是为了进一步实现游戏的公平环境，保证用户使用服务的质量。
        </p>
        <p>二、 限制性行为或活动</p>
        <p>禁止用户从事的侵害线上游戏公平性的行为包括但不限于：</p>
        <p>
          1.利用反向工程、编译或反向编译、反汇编等技术手段制作软件对游戏进行分析、修改、攻击，最终达到作弊的目的。
        </p>
        <p>
          2.使用任何外挂程序或游戏修改程序，对线上游戏软件进行还原工程、编译或译码，包括修改本软件所使用的任何专有通讯协议，或对动态随机存取内存（RAM）中资料进行修改或锁定。
        </p>
        <p>
          3.使用异常的方法登录游戏、使用网络加速器等外挂软件或机器人程式等恶意破坏服务设施、扰乱正常服务秩序的行为。
        </p>
        <p>
          4.传播外挂、封包、加速，及其它各种作弊软件程序，或组织、教唆他人使用此类软件程序，或销售此类软件程序而为私人或组织谋取经济利益。
        </p>
        <p>
          5.使用任何方式或方法，试图攻击提供游戏服务的相关服务器、路由器、交换机以及其它设备，以达到非法获得或修改未经授权的数据资料、影响正常游戏服务，以及其它危害性目的的任何行为。
        </p>
        <p>
          6.利用线上游戏系统可能存在的技术缺陷或漏洞而以各种形式为自己及他人牟利。
        </p>
        <p>
          7.利用在使用不鲸游戏网络游戏平台提供的各种收费或免费网络游戏过程中所产生并储存于不鲸游戏网络游戏平台中的全部或部分数据信息，以各种形式为自己及他人牟利。
        </p>
        <p>三、法律适用</p>
        <p>
          1.不鲸游戏及其原始授权人拥有不鲸游戏自行开发或者代理的不鲸游戏网络游戏及其它收费服务相关的一切合法版权或者使用权，受《中华人民共和国著作权法》等相关法律法规的保护，任何侵害游戏公平性的事件均被视为针对该权利的违法行为，应按《中华人民共和国著作权法》等相关法律法规之规定承担相应法律责任。
        </p>
        <p>
          2.本条款作为《不鲸游戏用户协议》的补充性内容，是用户使用了游戏服务后，即构成的与不鲸游戏的合同关系的协议内容，受《中华人民共和国合同法》等相关法律法规的保护，任何侵害游戏公平性的事件均被视为对《不鲸游戏用户协议》及本条款的违反，应按《中华人民共和国合同法》等相关法律法规之规定承担相应法律责任。
        </p>
        <p>
          3.任何侵害游戏公平性的事件还会被视为危及信息安全的行为，肇事人将可能违反中华人民共和国《计算机信息系统安全保护条例》、《计算机软件保护条例》、《计算机信息网络国际联网安全保护管理办法》之规定，而承担相应法律责任。
        </p>
        <p>
          4.如侵害游戏公平性的行为系由其它线上游戏相关企业所实施，或其雇佣或教唆他人实施，其行为亦被视为违反《中华人民共和国反不正当竞争法》，并承担相应法律责任。
        </p>
        <p>四、相应措施</p>
        <p>
          不鲸游戏对任何侵害网络游戏运行环境和公平性的行为有权采取一切必要措施，包括但不限于：
        </p>
        <p>
          1.有权单方面解除和发生此类行为的帐号所对应的用户（以下简称&ldquo;肇事人&rdquo;）之间的合同关系，由此产生的一切后果由当事人自行承担。
        </p>
        <p>
          2.有权暂停、终止帐号使用或直接删除发生此类行为的用户帐号、角色及相关所有资料。
        </p>
        <p>3.有权采取其它任何形式的保护性措施。</p>
        <p>
          4.对于违反国家刑事和行政法律法规的肇事人，交由有关国家机关追究其相应责任。
        </p>
        <p>5.保留向肇事人提起诉讼，追索经济损失赔偿的权利。</p>
        <p>玩家守则</p>
        <p>一、用户违规行为的基本处理方法的释义</p>
        <p>
          1.关闭聊天功能：强制暂停用户角色的在线对话功能，使用户角色无法与其它用户对话，直到本次处罚到期或取消。
        </p>
        <p>2.强制离线：强制将用户帐号注销，结束用户游戏程序的执行。</p>
        <p>
          3.监禁：强制将用户角色移置于独立不可出入的地图中直到本次处罚到期或取消。
        </p>
        <p>4.删除角色：强制删除用户的特定角色。</p>
        <p>5.暂停游戏帐号：暂时停止用户游戏帐号及服务的使用权。</p>
        <p>6.删除游戏帐号：强制删除用户游戏帐号及其游戏权利。</p>
        <p>二、用户和用户之间的几种违规行为及处罚措施</p>
        <p>1.行为：辱骂、人身攻击其它玩家，妨碍其他玩家正常游戏。</p>
        <p>
          处罚措施：情节较轻者采取禁言，情节严重者监禁，再犯者暂停其帐号及各项服务。
        </p>
        <p>
          2.行为：在GM主持的帮会竞赛、PK竞赛等任务性活动之外，以团伙形式或以暴力手段强行夺取他人物品。
        </p>
        <p>
          处罚措施：利用群体力量强行抢夺他人物品，破坏游戏的公平性，发现将会被监禁，屡教不改者暂停帐号。
        </p>
        <p>
          3.行为：不断吵闹、重复发言多次、不断打广告、恶意刷屏等侵犯大多数用户权益；恶意封堵狭窄的路口给其他用户造成行动不便，以及其它恶意连续骚扰他人，影响他人进行游戏，不听劝阻。
        </p>
        <p>处罚措施：将被关闭聊天功能、监禁直至封帐号。</p>
        <p>4.行为：以骚扰其它用户为目的而持续进行恶意PK行为且劝阻不听者。</p>
        <p>处罚措施：强行退出游戏并暂停帐号。</p>
        <p>三、用户和游戏本身的几种违规行为及处罚措施</p>
        <p>
          1.行为：使用违反命名规则之角色名称：包括但不限于含有人身攻击、淫秽、辱骂、反动及其他危害本游戏形象、同GM服务角色名故意相似扰乱正常服务秩序以及国家利益和社会公德性质的文字。
        </p>
        <p>
          处罚措施：暂停帐号，用户应当主动联系不鲸游戏修改角色名称。对于情节严重的，立刻删除该帐号。
        </p>
        <p>
          2.行为：恶性破坏不鲸游戏服务设施，包括但不限于：利用编译或反编译程序修改游戏资料、利用各种方式攻击或入侵游戏服务器、使用异常的方法登录游戏、使用网络加速器等外挂软件或机器人程式等恶意破坏服务设施、扰乱正常服务秩序的行为。
        </p>
        <p>处罚措施：直接删除该用户帐号，同时保留对此行为之一切法律追诉权。</p>
        <p>3.行为：利用系统的BUG、漏洞为自己及他人牟利。</p>
        <p>
          处罚措施：一经发现或经用户举报查证属实后，不鲸游戏有权清除该用户的不当获利，对于情节严重的，不鲸游戏有权直接删除该帐号。
        </p>
        <p>
          4.行为：利用游戏提供的功能进行非法实物交易等违反国家法律法规的行为。
        </p>
        <p>
          处罚措施：上述行为一经发现，立即注销帐号，并交由国家有关机构处理。
        </p>
        <p>
          5、行为：未满十八周岁的青少年需按照官网的家长监护工程提交申请，适度登陆游戏，避免沉迷游戏伤身。
          处罚措施：上述行为查证属实，立即封停账号，待完成申请后，恢复账号正常登陆。
        </p>
        <p>四、用户和管理员之间的几种违规行为及处罚措施</p>
        <p>1.行为：在游戏中无故呼叫管理员（GM），并且频繁地发骚扰信息给GM。</p>
        <p>处罚措施：关闭该玩家聊天功能、监禁、暂停帐号。</p>
        <p>2.行为：不服从管理员安排、对管理员辱骂。</p>
        <p>处罚措施：处以监禁、停止帐号等处罚。</p>
        <p>3.行为：在游戏中假冒GM或其他客户服务人员。</p>
        <p>处罚措施：删除该角色，如果多次发现将删除该游戏帐号。</p>
        <p>五、对盗号及其相关行为的处罚措施</p>
        <p>
          为保障玩家能愉悦的体验游戏，保障玩家的正当利益不受到侵害，公司对盗号及盗号相关行为（包括但不限于盗取帐号、游戏数据、玩家个人资料、协助盗号者操作及转移游戏道具等行为）将予以严厉打击和处罚。一经查证属实或应有权机关的要求，不鲸游戏有权视具体情况立即采取封停帐号、删除档案等处罚措施，情节严重的，不鲸游戏保留对涉案玩家追究法律责任的权利。
        </p>
        <p>
          尽管您未主动参与盗号，但如您获得的游戏虚拟物品是他人通过盗号方式获得，而后转移或者分配给您的，为了维护被盗玩家的权益及游戏中公平公正的游戏秩序，不鲸游戏有权收回您购买的该游戏虚拟物品。
        </p>
        <p>
          &nbsp;在查证玩家被盗事件的过程中，为能够快速调查、处理问题，不鲸游戏可能会请相关玩家协助调查，其方式包括但不限于禁止登录调查、线上交流、线下交流等。
        </p>
        <p>六、声明</p>
        <p>
          请玩家遵守以上规定，若因违规而受到处分，由此引起的任何损失，由玩家自行负责。GM将有权立即暂停任何可能危害游戏系统的游戏帐号上线权利而不先行告之，所有GM都将严格按照中立和公正原则，不偏袒、不徇私，并且严格按照本条例的规定，考量实际违规情况，不减轻和从重处罚。
        </p>
        <p>用户须知</p>
        <p>
          为了各位玩家能够更好的享受不鲸游戏网络游戏中的每个精彩时刻，您应该遵守以下守则：
        </p>
        <p>
          一、玩家之间人人平等，这是我们对您进行服务的准则。我们努力使每个玩家获得同等质量的服务，您也应该做到尊重其他玩家的权利，不进行任何可能会侵害其他玩家游戏质量的活动。
        </p>
        <p>
          二、网络游戏基于人人互动才能精彩，为了使你在虚拟世界中拥有更多的朋友，请在游戏中注意言行举止，礼貌用语。对于语言污秽的玩家，GM在获得举报后将会采取禁言等措施进行管理。
        </p>
        <p>
          三、公平游戏是享受游戏的前提，正如每一个人都知道的，任何程序都存在BUG，虽然我们尽力解决已知的BUG，但是不能排除其它BUG存在的可能性。作为游戏玩家，应该能够在发现BUG时主动向GM汇报。严禁利用任何BUG进行非正常性的获利，这些获利包括但不限于获得额外的经验值，道具物品等。一经发现，GM将有权利对该玩家处以监禁处罚，甚至关闭帐户。
        </p>
        <p>
          四、玩家应该不参与以真实货币或物品进行的虚拟货币或虚拟物品的交易行为，更不能对利用游戏漏洞产生的虚拟物品或虚拟货币进行交易及使用，我们将不对这一类交易中产生的任何问题进行支持或者保障，同时将对游戏造成的影响采取必要的保护措施，如删除物品等，这些行为要求还包括以真实货币购买游戏帐户等。
        </p>
        <p>
          五、减少玩家之间PK活动，虽然程序中允许进行PK行为，但任何恶意PK均将影响你在游戏中与他人正常的交流活动，友善待人能够让你获得更大的游戏乐趣。
        </p>
        <p>
          六、每个玩家均有请求GM帮助的权利，但是，为了能够让更多的人获得帮助，每个玩家应该自觉不与GM闲聊。GM有权利不回答与工作无关的闲聊话题。
        </p>
        <p>
          七、每个玩家均有监督GM的权利，如果您发现GM任何违规行为，均可以采用游戏提供的截图方式（保障图片真实性）获取现场，并将投诉内容提交给不鲸游戏，不鲸游戏将会对该名GM进行处理。
        </p>
        <p>
          八、网络资源空间是网络游戏顺利运行的保证，对网络资源空间的浪费将最终影响每个玩家对网络游戏的使用。每个玩家均应珍惜网络资源空间，遵守游戏规则及规定，避免任何对网络资源空间的浪费。
        </p>
        <p>
          九、青少年用户特别提示：青少年用户必须遵守健康游戏忠告--抵制不良游戏，拒绝盗版游戏；注意自我保护，谨防受骗上当；适度游戏益脑，沉迷游戏伤身；合理安排时间，享受健康生活。
        </p>
        <p>十、理解并且遵守不鲸游戏颁布的玩家守则和服务承诺以及其它规定。</p>
        <p>&nbsp;</p>
        天津群溢科技有限公司
      </div>
      <div class="back" @click="backClick">
        <img class="back_img" src="@/assets/m/img/user/Back.png" /><span
          >返回</span
        >
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "userAgreement",
  data() {
    return {};
  },
  methods: {
    backClick() {
      this.$emit("myswiperOpen");
      this.$emit("agreementClick", false);
    },
  },
};
</script>

<style scoped>
.userAgreement {
  width: 100%;
  height: 100%;
}
.box-card {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  /* width: 48vh; */
  height: 85vh;
  background-color: rgba(242, 247, 249, 1);
  text-align: center;
  font-size: 1.5vh;
  border-radius: 8px;
}
.title {
  width: 25vh;
  height: 3.5vh;
  border-radius: 50px;
  background: #3f3f3f;
  color: #ffffff;
  text-align: center;
  line-height: 3.5vh;
  letter-spacing: 1.5vh;
  margin: auto;
  margin-bottom: 2.5vh;
}
.center {
  height: 68vh;
  line-height: 3vh;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-bottom: 2.5vh;
  margin-right: -1vh;
  text-align: left;
  padding-right: 1vh;
  /* text-indent: 2em; */
}
.center::-webkit-scrollbar {
  width: 2px;
  height: 0.5px;
}
.center::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 3rem;
  background: #bbc0c2;
}
.center::-webkit-scrollbar-track {
  border-radius: 10px;
}
.back_img {
  width: 2vh;
  vertical-align: middle;
  margin: 0.5vh;
}
.back:hover {
  cursor: pointer;
}
</style>