<template>
  <div class="registered">
    <div v-if="!userAgreementFlag">
      <div class="registered_input">
        <div class="registered_input_box registered_input_box1">
          <el-input
            class="registered_input_item"
            maxlength="11"
            v-model="form.account"
            placeholder="手机号码："
          >
          </el-input>
        </div>
        <div class="registered_input_box registered_input_box2">
          <el-input
            class="registered_input_item"
            maxlength="16"
            show-password
            v-model="form.password"
            placeholder="设置密码："
          ></el-input>
        </div>
        <div class="registered_input_box registered_input_box3">
          <el-input
            class="registered_input_item"
            v-model="form.name"
            placeholder="真实姓名："
          >
          </el-input>
        </div>
        <div class="registered_input_box registered_input_box4">
          <el-input
            class="registered_input_item"
            maxlength="18"
            v-model="form.idno"
            placeholder="身份证号："
          ></el-input>
        </div>
        <div class="registered_input_box registered_input_box5">
          <el-input
            class="registered_input_item registered_input_item3"
            v-model="form.code"
            maxlength="4"
            placeholder="图片验证码："
          ></el-input>
          <img @click="getImg" class="registered_input_img1" :src="imgSrc" />
        </div>
        <div class="registered_input_box registered_input_box6">
          <el-input
            class="registered_input_item registered_input_item3"
            maxlength="6"
            v-model="form.msgCode"
            placeholder="手机验证码："
          ></el-input>
          <u
            v-if="phoneCodeFlag"
            class="registered_input_img2"
            @click="getMsgCode"
            >获取验证码</u
          >
          <u v-if="!phoneCodeFlag" class="registered_input_img2"
            >{{ phoneCode }}秒</u
          >
        </div>
        <div class="agreementBox">
          <el-checkbox v-model="form.checked"></el-checkbox>
          <span class="agreement" @click="agreementClick(true)"
            >我已经阅读并同意《用户注册服务协议》</span
          >
          <p class="registered_back" @click="backClick">
            <img src="@/assets/m/img/user/Back.png" /><span>返回</span>
          </p>
        </div>
      </div>
      <div class="registered_submit" @click="onSubmit">提<span></span>交</div>
    </div>
    <userAgreement
      v-if="userAgreementFlag"
      class="userAgreementBox"
      @agreementClick="agreementClick"
      @myswiperOpen="myswiperOpen"
    ></userAgreement>
  </div>
</template>

<script>
import userAgreement from "../userAgreement";
export default {
  name: "registered",
  props: ["imgSrc"],
  components: { userAgreement },
  data() {
    return {
      userAgreementFlag: false,
      phoneCodeFlag: true,
      phoneCode: "",
      form: {
        account: "",
        password: "",
        name: "",
        idno: "",
        code: "",
        msgCode: "",
        checked: true,
      },
    };
  },
  methods: {
    myswiperOpen() {
      this.$emit("myswiperOpen");
    },
    check(callback) {
      let accountReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!accountReg.test(this.form.account)) {
        this.$message({
          type: " error",
          message: "请输入正确的手机号",
          center: true,
        });
        return;
      }
      let passReg = /(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,16}/;
      if (!passReg.test(this.form.password)) {
        this.$message({
          type: " error",
          message: "请输入6-16位字母、数字或标点符号组合的密码",
          center: true,
        });
        return;
      }
      let nameReg = /^[\u4e00-\u9fa5]+$/;
      if (!nameReg.test(this.form.name)) {
        this.$message({
          type: " error",
          message: "真实姓名必须为汉字",
          center: true,
        });
        return;
      }
      let idnoReg = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;
      if (!idnoReg.test(this.form.idno)) {
        this.$message({
          type: " error",
          message: "请输入正确的身份证号",
          center: true,
        });
        return;
      }
      // let codeReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{4,16}$/;
      if (this.form.code.length != 4) {
        this.$message({
          type: " error",
          message: "请输入正确的图片验证码",
          center: true,
        });
        this.form.code = "";
        this.$emit("getImgsrc");
        return;
      }
      if (callback && typeof callback == "function") {
        callback();
      }
    },
    getImg() {
      this.$emit("getImgsrc");
    },
    getMsgCode() {
      let _this = this;
      _this.check(() => {
        _this.$emit("getRegisteredMsgCode", _this.form);
      });
    },
    onSubmit() {
      let _this = this;
      _this.check(function () {
        let msgCodeReg = /^\d{6}$/;
        if (!msgCodeReg.test(_this.form.msgCode)) {
          _this.$message({
            type: " error",
            message: "请输入正确的手机验证码",
            center: true,
          });
          return;
        }
        if (!_this.form.checked) {
          _this.$message({
            type: " error",
            message: "需勾选用户注册协议",
            center: true,
          });
          return;
        }
        _this.$emit("submitClick", _this.form);
      });
    },
    agreementClick(flag) {
      if (flag) {
        this.$emit("myswiperStop");
      }
      this.userAgreementFlag = flag;
    },
    backClick() {
      this.$emit("registeredClick", "0");
    },
  },
};
</script>

<style scoped>
@import url("../../../assets/m/fonts/fonts.css");
.registered {
  width: 6.8rem;
  height: 7rem;
  background-color: #e8f2f8;
  border-radius: 8px;
  text-align: center;
  color: #3d4a53;
  position: relative;
  font-size: 0.35rem;
  padding-top: 0.1rem;
}
.registered_input {
  margin-top: 0.2rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  /* line-height: 0.65rem; */
}
.registered_input_item >>> .el-input__inner {
  font-size: 0.25rem;
  border-radius: 15px;
  height: 0.6rem;
  background-repeat: no-repeat;
  background-position: 0.1rem center;
  background-size: 0.38rem;
  font-family: "YSHaoShenTi-2";
  background-color: #e8f2f8;
  line-height: normal;
}
.registered_input_box {
  height: 0.7rem;
  line-height: 0.7rem;
  margin-bottom: 0.05rem;
}
.registered_input_box5,
.registered_input_box6 {
  display: flex;
}
.registered_input_img1 {
  width: 2rem;
  height: 0.6rem;
  margin-left: 0.1rem;
  margin-top: 0.05rem;
}
.registered_input_img2 {
  width: 2rem;
  height: 0.6rem;
  margin-left: 0.1rem;
  font-size: 0.25rem;
}
.registered_back {
  margin-top: 0.2rem;
  height: 0.5rem;
}
.registered_back img {
  width: 0.25rem;
  vertical-align: middle;
  margin: 0.1rem;
}
.agreementBox {
  margin-top: 0.15rem;
  font-size: 0.25rem;
}
.agreement {
  margin-left: 0.1rem;
  height: 0.1rem;
  color: #788085;
}
.registered_submit {
  width: 100%;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 7px;
  background: #3d4a53;
  color: white;
  position: absolute;
  bottom: 0;
  font-size: 0.35rem;
}
.registered_submit span {
  display: inline-block;
  width: 2vh;
}
.userAgreementBox {
  width: 6rem;
  z-index: 10;
}
</style>