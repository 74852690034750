<template>
  <div class="user">
    <login
      ref="login"
      v-if="whether === '0' ? true : false"
      class="login"
      :imgSrc="imgSrc"
      @getImgsrc="getImgsrc"
      @registeredClick="registeredClick"
      @loginClick="loginClick"
    ></login>
    <registered
      ref="registered"
      v-if="whether === '1' ? true : false"
      class="registered swiper-no-swiping stop-swiping"
      :imgSrc="imgSrc"
      @getImgsrc="getImgsrc"
      @getRegisteredMsgCode="getRegisteredMsgCode"
      @submitClick="submitClick"
      @registeredClick="registeredClick"
      @myswiperStop="myswiperStop"
      @myswiperOpen="myswiperOpen"
    ></registered>
  </div>
</template>

<script>
import md5 from "js-md5";
import login from "./user/login";
import registered from "./user/registered";
import user from "../../api/user";
export default {
  name: "user",
  components: {
    login,
    registered,
    code: "",
  },
  data() {
    return {
      downDynamic: "",
      upDynamic: "",
      whether: "0",
      imgSrc: "",
      basePath: this.$axiosUrl,
    };
  },
  mounted() {
    this.getImgsrc();
  },
  methods: {
    myswiperStop() {
      this.$emit("myswiperStop");
    },
    myswiperOpen() {
      this.$emit("myswiperOpen");
    },
    getImgsrc() {
      let from = { action: "captcha", method: "get" };
      user.login(from).then((res) => {
        this.code = res.data.code;
        this.imgSrc =
          `${this.basePath}?action=captcha&method=set&key=` + this.code;
      });
    },
    registeredClick(flag) {
      this.whether = flag;
      this.getImgsrc();
    },
    loginClick(data) {
      let from = {
        action: "request",
        method: "login",
        key: this.code,
        account: data.account,
        password: md5(data.password),
        code: data.code,
      };
      user.login(from).then((res) => {
        let code = res.data.code;
        let loginErr = {
          "-1": "系统繁忙",
          0: "验证码不正确",
          10: "参数验证不正确",
          10112305: "登录成功",
          10112306: "暂时无法登陆，请联系客服",
          10112301: "账号或密码有误",
          10112302: "账号或密码有误",
          10112303: "账号或密码有误",
          other: "登录失败，错误码：",
        };
        let msg = loginErr[code] ? loginErr[code] : loginErr["other"] + code;
        this.$message({
          type: " error",
          message: msg,
          center: true,
        });
        if (code == "10112305") {
          localStorage.setItem("bjAccountId", res.data.accountid),
            localStorage.setItem("bjToken", res.data.token);
          this.$emit("loginCliCk");
        } else {
          this.$refs.login.form.code = "";
          this.getImgsrc();
        }
      });
    },
    getRegisteredMsgCode(from) {
      let _this = this;
      let data = {
        action: "request",
        method: "send",
        key: this.code,
        account: from.account,
        password: md5(from.password),
        code: from.code,
      };
      user.login(data).then((res) => {
        let MsgCodeErr = {
          "- 1": "  系统繁忙",
          0: "验证码不正确",
          10: "参数验证不正确",
          10115204: "发送成功，设置60秒倒计时",
          10115205: "短信发送失败",
          other: "发送失败，错误码：",
        };
        let code = res.data.code;
        let msg = MsgCodeErr[code]
          ? MsgCodeErr[code]
          : MsgCodeErr[other] + code;
        if (code == 10115204) {
          _this.$message({
            type: " error",
            message: "发送成功，设置60秒倒计时",
            center: true,
          });
          _this.$refs.registered.phoneCodeFlag = false;
          let timer_num = 60;
          _this.$refs.registered.phoneCode = timer_num;
          let timeClock = setInterval(function () {
            timer_num--;
            _this.$refs.registered.phoneCode = timer_num;
            if (timer_num == 0) {
              clearInterval(timeClock);
              _this.$refs.registered.phoneCodeFlag = true;
            }
          }, 1000);
        } else {
          this.$message({
            type: " error",
            message: msg,
            center: true,
          });
          _this.$refs.registered.form.code = "";
          _this.getImgsrc();
        }
      });
    },
    submitClick(from) {
      let data = {
        action: "request",
        method: "register",
        key: this.code,
        account: from.account,
        password: md5(from.password),
        realname: encodeURI(encodeURI(from.name)),
        idno: from.idno,
        msgCode: from.msgCode,
        code: from.code,
      };
      user.login(data).then((res) => {
        let subErr = {
          "-1 ": "系统繁忙",
          0: "验证码不正确",
          10: "参数验证不正确",
          10112305: "注册成功",
          10111402: "短信验证码不存在",
          10111403: "短信验证码已过期",
          10111405: "注册失败",
          10111406: "暂时无法注册，请联系客服",
          other: "发送失败，错误码：",
        };
        let code = res.data.code;
        let msg = subErr[code] ? subErr[code] : subErr[other] + code;
        this.$message({
          type: " error",
          message: msg,
          center: true,
        });
        if (code == "10112305") {
          localStorage.setItem("bjAccountId", res.data.accountid),
            localStorage.setItem("bjToken", res.data.token);
          this.$emit("loginCliCk");
        } else {
          this.$refs.registered.form.code = "";
          this.getImgsrc();
        }
      });
    },
  },
};
</script>

<style scoped>
@import url("../../assets/pc/animation/animation.css");
.user {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  animation: fade-in;
  animation-duration: 1.5s;
  -webkit-animation: fade-in 1.5s;
}
.login,
.registered {
  margin-top: 30vh;
}
.el-input__prefix,
.el-input__suffix {
  top: -3px !important;
}
</style>