<template>
  <div class="customerService">
    <div class="customerService_top">
      <!-- <div class="customerService_top_imgBox">
        <img
          class="customerService_top_img"
          src="@/assets/pc/img/customerService/QrCode.png"
        />
        <p>官方客服QQ公众号</p>
      </div>
      <div class="contact_top_contact">
        <div class="contact_top_contact_item">
          <img src="@/assets/pc/img/customerService/email.png" /><span
            >客服邮箱：GM@bujgame.com</span
          >
        </div>
        <div class="contact_top_contact_item">
          <img src="@/assets/pc/img/customerService/phone.png" /><span
            >客服电话：010-53653505</span
          >
        </div>
        <div class="contact_top_contact_item">
          <img src="@/assets/pc/img/customerService/QQ.png" /><span
            >官方客服：800171023</span
          >
        </div>
      </div> -->
      <div class="contactBox">
        <!-- <div class="qq service">
          <span class="qq_bg service_icon"></span>
          <p class="qq_line line"></p>
          <div class="qq_center">
            <p>官方客服QQ</p>
            <p>800171023</p>
          </div>
        </div> -->
        <div class="phone service">
          <span class="phone_bg service_icon"></span>
          <p class="phone_line line"></p>
          <div class="phone_center">
            <p>客服电话</p>
            <p>010-53653505</p>
          </div>
        </div>
        <div class="email service">
          <span class="email_bg service_icon"></span>
          <p class="email_line line"></p>
          <div class="email_center">
            <p>客服邮箱</p>
            <p>GM@bujgame.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  methods: {
    
  },
  mounted() {
    
  },
};
</script>

<style scoped>
@import url("../../assets/pc/animation/animation.css");
.customerService {
  width: 100%;
  height: 100%;
  text-align: center;
  letter-spacing: 0.3vh;
  box-sizing: border-box;
  padding-top: 20vh;
  letter-spacing: 1px;
}
.customerService_top {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 38vh;
  padding: 5vh;
  box-sizing: border-box;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.1);
}
.customerService_top_imgBox {
  margin: auto;
  width: 23vh;
  height: 23vh;
  box-sizing: border-box;
  border-radius: 8px;
  text-align: center;
  padding-top: 3vh;
  margin-bottom: 4vh;
  background-color: rgba(225, 225, 225, 0.8);
  animation: fade-in;
  animation-duration: 2s;
  -webkit-animation: fade-in 2s;
}
.customerService_top_imgBox p {
  margin-top: 0.5vh;
  font-size: 1.5vh;
}
.customerService_top_img {
  width: 15vh;
  height: 15vh;
}
.contact_top_contact {
  text-align: center;
  color: white;
  font-size: 1.5vh;
  margin-top: 2vh;
  animation: fade-in;
  animation-duration: 5s;
  -webkit-animation: fade-in 5s;
}
.contact_top_contact_item {
  display: inline-block;
  margin-left: 5vh;
  margin-right: 5vh;
}
.contact_top_contact span {
  margin-left: 1vh;
}
.contact_top_contact img {
  width: 2vh;
  vertical-align: middle;
}

.contactBox {
  display: flex;
  justify-content: center;
  width: 1000px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fade-in;
  animation-duration: 2s;
  -webkit-animation: fade-in 2s;
}
.service {
  width: 150px;
  height: 150px;
  margin-left: 50px;
  margin-right: 50px;
  border: white solid 1px;
  border-radius: 50%;
  color: #f2f5f9;
  font-size: 14px;
  position: relative;
}
.service:hover {
  animation: rightEaseInAnimate 0.5s ease 1;
  animation-fill-mode: forwards;
}
@keyframes rightEaseInAnimate {
  0% {
    background: rgba(255, 255, 255, 0);
  }
  50% {
    background: rgba(255, 255, 255, 0.5);
  }
  100% {
    background: rgba(255, 255, 255, 1);
  }
}
@keyframes transEaseInAnimate {
  0% {
    top: 65px;
  }
  100% {
    top: 80px;
  }
}
@keyframes transEaseInAnimate1 {
  0% {
    top: 30px;
  }
  100% {
    top: 20px;
  }
}
.service_icon {
  position: absolute;
  top: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.qq_bg {
  width: 22px;
  height: 23px;
  background-image: url("../../assets/pc/img/customerService/QQ.png");
}
.phone_bg {
  width: 23px;
  height: 23px;
  background-image: url("../../assets/pc/img/customerService/phone.png");
}
.email_bg {
  width: 24px;
  height: 16px;
  margin-top: 5px;
  background-image: url("../../assets/pc/img/customerService/email.png");
}
.service:hover .service_icon {
  animation: transEaseInAnimate1 0.5s ease 1;
  animation-fill-mode: forwards;
}
.service:hover .qq_bg {
  background-image: url("../../assets/pc/img/customerService/QQ1.png");
}
.service:hover .phone_bg {
  background-image: url("../../assets/pc/img/customerService/phone1.png");
}
.service:hover .email_bg {
  background-image: url("../../assets/pc/img/customerService/email1.png");
}
.fillModeWards {
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.qq:hover {
  color: #0a2b58;
  cursor: pointer;
  background: white;
}
.service:hover .line {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 1px;
  background: #0a2b58;
  animation: fade-in;
  animation-duration: 1s;
  -webkit-animation: fade-in 1s;
}
.qq:hover .qq_center {
  animation: transEaseInAnimate 0.5s ease 1;
  animation-fill-mode: forwards;
}
.phone:hover {
  background: white;
  color: #0a2b58;
  cursor: pointer;
}
.phone:hover .phone_center {
  animation: transEaseInAnimate 1s ease 1;
  animation-fill-mode: forwards;
}
.email:hover {
  background: white;
  color: #0a2b58;
  cursor: pointer;
}
.email:hover .email_center {
  animation: transEaseInAnimate 1s ease 1;
  animation-fill-mode: forwards;
}
.qq div {
  width: 200px;
  position: absolute;
  top: 65px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.phone div {
  width: 200px;
  position: absolute;
  top: 65px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.email div {
  width: 200px;
  position: absolute;
  top: 65px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
</style>