<template>
  <div class="index">
    <div class="video-container">
      <div :style="fixStyle" class="filter">
        <img v-show="logoFlag" class="logo" src="@/assets/pc/img/logo.png" />
        <div v-if="login" class="userbox">
          <p>您好，{{ userId }}</p>
          <p>|</p>
          <p @click="logout" class="logoutBtn">退出</p>
        </div>
        <!--菜单栏-->
        <div class="navigation">
          <div :class="current === 0 ? 'animation' : 'navigationItem'">
            <span v-show="current === 0 ? false : true">/ </span>
            <span @click="navigationClick(0)">首页</span>
            <span
              :class="current === 0 ? 'navigationItem_hover' : ''"
              v-if="current === 0 ? true : false"
            >
              HOME<span class="navigationItem_line"></span
            ></span>
          </div>
          <div :class="current === 1 ? 'animation' : 'navigationItem'">
            <span v-show="current === 1 ? false : true">/ </span>
            <span @click="navigationClick(1)">关于我们</span>
            <span
              :class="current === 1 ? 'navigationItem_hover' : ''"
              v-if="current === 1 ? true : false"
            >
              ABOUT<span class="navigationItem_line"></span
            ></span>
          </div>
          <div :class="current === 2 ? 'animation' : 'navigationItem'">
            <span v-show="current === 2 ? false : true">/ </span>
            <span @click="navigationClick(2)">客服中心</span>
            <span
              :class="current === 2 ? 'navigationItem_hover' : ''"
              v-if="current === 2 ? true : false"
            >
              SUPPORT<span class="navigationItem_line"></span
            ></span>
          </div>
          <div
            v-if="lastNavigation"
            :class="current === 3 ? 'animation' : 'navigationItem'"
          >
            <span v-show="current === 3 ? false : true">/ </span>
            <span @click="navigationClick(3)">用户中心</span>
            <span
              :class="current === 3 ? 'navigationItem_hover' : ''"
              v-if="current === 3 ? true : false"
            >
              ACCOUNT<span class="navigationItem_line"></span
            ></span>
          </div>
        </div>
        <div class="router-view">
          <swiper ref="Myswiper" :options="swiperOption" :current="current">
            <swiper-slide>
              <homePage v-if="current === 0" class="view homePage">1</homePage>
            </swiper-slide>
            <swiper-slide>
              <aboutUs v-if="current === 1" class="view aboutUs"></aboutUs>
            </swiper-slide>
            <swiper-slide>
              <customerService
                v-if="current === 2"
                class="view customerService"
              ></customerService>
            </swiper-slide>
            <swiper-slide v-if="lastNavigation" class="stop-swiping">
              <user
                v-if="current === 3"
                class="view user"
                @loginCliCk="loginCliCk"
                @myswiperStop="myswiperStop"
                @myswiperOpen="myswiperOpen"
              ></user>
            </swiper-slide>
            <swiper-slide>
              <div class="foot">
                <div>
                  <p>
                    <a href="https://beian.miit.gov.cn " target="_blank"
                      >备案号：津ICP备19006017号-2</a
                    >    联系电话010-53652155
                  </p>
                  <p>天津群溢科技有限公司版权所有</p>
                  <p>
                    <a
                      :href="require('../../assets/public/Group-1.jpg')"
                      target="_blank"
                      >ICP证:津B2-20190075</a
                    >
                  </p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <video :style="fixStyle" autoplay loop muted>
        <source src="@/assets/pc/video/2048.mp4" type="video/mp4" />
        浏览器不支持 video 标签，建议升级浏览器。
        <source src="@/assets/pc/video/2048.mp4" type="video/webm" />
        浏览器不支持 video 标签，建议升级浏览器。
      </video>
    </div>
  </div>
</template>

<script>
import homePage from "./homePage";
import aboutUs from "./aboutUs";
import customerService from "./customerService";
import user from "./user";
export default {
  name: "index",
  components: { homePage, aboutUs, customerService, user },
  data() {
    const _this = this;
    return {
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          paginationType: "custom",
        },
        autoHeight: true,
        height: window.innerHeight,
        mousewheel: true,
        direction: "vertical",
        slidesPerView: "auto",
        slideToClickedSlide: true,
        observer: true,
        observeParents: true,
        observer: true,
        observeParents: true,
        on: {
          slideChangeTransitionEnd: function () {
            _this.current = this.activeIndex;
          },
        },
      },
      login: false,
      userId: "",
      logoFlag: true,
      fixStyle: "",
      current: 0,
      lastNavigation: true,
    };
  },
  watch: {
    current(ne) {
      if (ne === 3) {
        this.logoFlag = false;
      } else {
        this.logoFlag = true;
      }
    },
  },
  mounted() {
    this.initial();
    this.loginCliCk();
  },
  methods: {
    loginCliCk() {
      let id = localStorage.getItem("bjAccountId");
      let token = localStorage.getItem("bjToken");
      if (id || token) {
        this.login = true;
        this.userId = id;
        this.current = 0;
        this.$refs.Myswiper.$swiper.activeIndex = 0;
        this.lastNavigation = false;
      }
    },
    logout() {
      this.login = false;
      localStorage.removeItem("bjAccountId");
      localStorage.removeItem("bjToken");
      this.lastNavigation = true;
    },
    initial() {
      this.navigationFlag = true;
      this.logoFlag = true;
    },
    navigationClick(id) {
      this.current = id;
      this.$refs.Myswiper.$swiper.activeIndex = id;
    },
    myswiperStop() {
      this.$refs.Myswiper.$swiper.mousewheel.disable();
    },
    myswiperOpen() {
      this.$refs.Myswiper.$swiper.mousewheel.enable();
    },
  },
};
</script>

<style scoped>
.index,
.video-container {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.video-container .filter {
  z-index: 1;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-image: url("../../assets/pc/img/square.png");
  background-repeat: no-repeat;
  background-position: 0vh 0vh;
}
.logo {
  position: absolute;
  width: 12vh;
  left: 5vh;
  top: 5vh;
}
.userbox {
  position: absolute;
  top: 6.5vh;
  right: 25vh;
  display: flex;
  color: #616f83;
  font-size: 1.5vh;
  z-index: 9;
}
.userbox p {
  margin-left: 10px;
}
.logoutBtn {
  text-decoration: underline;
  cursor: pointer;
}
.navigation {
  width: 19.5vh;
  position: absolute;
  right: 0vh;
  top: 5vh;
  line-height: 5vh;
  font-size: 1.5vh;
  z-index: 1000;
  letter-spacing: 2px;
  color: #1b2d45;
}
.navigationItem {
  margin-left: 5vh;
  transition: 0.3s all ease;
}
.navigationItem:hover {
  -webkit-transform: translate(-30px, 0);
  -moz-transform: translate(-30px, 0);
  -ms-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}
.animation {
  animation: rightEaseInAnimate 1s ease 1;
  animation-fill-mode: forwards;
}
@keyframes rightEaseInAnimate {
  0% {
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }
}
.placeholder {
  display: inline-block;
  width: 3vh;
}
.navigationItem_hover {
  display: inline-block;
  text-align: left;
  padding-left: 1.5vh;
  text-overflow: ellipsis;
  width: 11vh;
  height: 3vh;
  position: relative;
  font-size: 1vh;
  letter-spacing: 0px;
}
.navigationItem_line {
  position: absolute;
  top: 2.9vh;
  left: 0vh;
  right: 0vh;
  margin-left: 0.5vh;
  display: inline-block;
  width: 100%;
  height: 0.1vh;
  background: #373737;
}
.navigationItem:hover {
  cursor: pointer;
}
a {
  text-decoration: none;
  color: black;
}
.router-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: 100vh !important;
}
.swiper-container .swiper-slide {
  height: 100% !important;
  /* background: white; */
}
.swiper-container .swiper-slide:last-child {
  height: 15% !important;
  /* background: white; */
}
.foot {
  width: 100%;
  height: 100%;
  background: white;
  color: #78808d;
  font-size: 1vh;
  text-align: 3vh;
  letter-spacing: 0.1vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.foot div {
  text-align: center;
}
.foot a {
  text-decoration: none;
  color: #78808d;
}
</style>