export default function() {

    var width = 750;
    var dw = document.documentElement.clientWidth;
    var _zoom = dw / width * 100;
    document.getElementsByTagName('html')[0].style.fontSize = _zoom + "px";


    // var deviceWidth = document.documentElement.clientWidth;
    // if (deviceWidth > 750) {
    //     deviceWidth = 7.5 * 50;
    // }

    // document.documentElement.style.fontSize = deviceWidth / 7.5 + 'px';

    // 禁止双击放大
    // document.documentElement.addEventListener('touchstart', function(event) {
    //     if (event.touches.length > 1) {
    //         event.preventDefault();
    //     }
    // }, false);
    // var lastTouchEnd = 0;
    // document.documentElement.addEventListener('touchend', function(event) {
    //     var now = Date.now();
    //     if (now - lastTouchEnd <= 300) {
    //         event.preventDefault();
    //     }
    //     lastTouchEnd = now;
    // }, false);
}