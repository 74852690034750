<template>
  <div class="login">
    <img class="login_logo" src="@/assets/pc/img/logo.png" />
    <div class="login_input">
      <div class="login_input_box login_input_box1">
        <el-input
          class="login_input_item login_input_item1"
          maxlength="11"
          v-model="form.account"
          placeholder="请输入手机号"
        ></el-input>
      </div>
      <div class="login_input_box login_input_box2">
        <el-input
          class="login_input_item login_input_item2"
          maxlength="16"
          show-password
          v-model="form.password"
          placeholder="请输入密码"
        ></el-input>
      </div>
      <div class="login_input_box login_input_box3">
        <el-input
          class="login_input_item login_input_item3"
          maxlength="4"
          v-model="form.code"
          placeholder="请输入验证码"
        ></el-input>
        <img @click="getImg" class="login_input_img" :src="imgSrc" />
        <!-- <img class="login_input_img" src="@/assets/pc/img/user/validation.png" /> -->
      </div>
    </div>
    <div class="login_registered" @click="registeredClick">注册账号 ></div>
    <div class="login_submit" @click="onSubmit">登<span></span>录</div>
  </div>
</template>

<script>
export default {
  name: "login",
  props: ["imgSrc"],
  data() {
    return {
      form: {
        account: "",
        password: "",
        code: "",
      },
    };
  },
  mounted() {},
  methods: {
    getImg() {
      this.$emit("getImgsrc");
    },
    registeredClick() {
      this.$emit("registeredClick", "1");
    },
    onSubmit() {
      let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg.test(this.form.account)) {
        this.$message({
          type: " error",
          message: "请输入正确的手机号",
          center: true,
        });
        return;
      }
      if (this.form.password.length > 16 || this.form.password.length < 6) {
        this.$message({
          type: " error",
          message: "请输入6-16位字符的密码",
          center: true,
        });
        return;
      }
      // let regs = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{4,16}$/;
      if (this.form.code.length != 4) {
        this.$message({
          type: " error",
          message: "请输入正确的验证码",
          center: true,
        });
        this.form.code = "";
        this.$emit("getImgsrc");
        return;
      }
      this.$emit("loginClick", this.form);
    },
  },
};
</script>

<style scoped>
@import url("../../../assets/pc/fonts/fonts.css");
.login {
  width: 290px;
  height: 330px;
  background-color: #e8f2f8;
  border-radius: 8px;
  text-align: center;
  color: #3d4a53;
  font-size: 14px;
  position: relative;
}
.login_logo {
  width: 100px;
  height: 45px;
  margin-top: 30px;
}
.login_input {
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
}
.login_input_box {
  margin-top: 12px;
  height: 35px;
  padding: 0;
}
.login_input_item >>> .el-input__inner {
  border-radius: 55px;
  height: 33px;
  background-repeat: no-repeat;
  background-position: 5px 5px;
  background-size: 23px;
  font-family: "YSHaoShenTi-2";
  font-size: 13px;
  background-color: #e8f2f8;
}
.login_input_item1 >>> .el-input__inner {
  background-image: url("../../../assets/pc/img/user/user.png");
  padding-left: 35px;
}
.login_input_item2 >>> .el-input__inner {
  background-image: url("../../../assets/pc/img/user/password.png");
  padding-left: 35px;
}
.login_input_item3 {
  width: 150px;
  float: left;
}
.login_input_box3 {
  display: flex;
}
.login_input_img {
  width: 100px;
  height: 33px;
  margin-left: 10px;
}
.login_registered {
  font-size: 13px;
  margin-top: 26px;
}
.login_registered,
.login_submit:hover {
  cursor: pointer;
}
.login_submit {
  width: 100%;
  height: 45px;
  font-size: 20px;
  border-radius: 7px;
  background: #3d4a53;
  color: white;
  line-height: 45px;
  position: absolute;
  bottom: 0;
}
.login_submit span {
  display: inline-block;
  width: 18px;
}
</style>