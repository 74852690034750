<template>
  <div class="aboutUs">
    <div class="aboutUs_title1">
      <span>ABOUT</span>
      <span>US</span>
    </div>
    <p class="aboutUs_title2">关于我们</p>
    <div class="aboutUs_title_img"></div>
    <div class="aboutUs_content">
      <!-- <img src="@/assets/pc/img/aboutUs/center.png" /> -->
      <p class="aboutUs_content_left">"不鲸"</p>
      <p class="aboutUs_content_right">
        意指不断突破创新，力寻游戏乐趣的精神，同时也寓意具有远大鲲鹏之志，象征着自由与勇往直前。<br />
        不鲸游戏致力于对移动游戏的打造及全球范围的发行与推广，为热爱游戏的玩家们制作高品质的移动游戏作品。<br /><br />
        作为一家游戏发行品牌，不鲸游戏希望将对世界的热情与游戏艺术的理解，倾注于充满希望与朝气的事业中。<br />
        同时，将游戏产品所阐述的艺术形态不断向玩家传达，推动移动游戏产品的革新！
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutUs",
};
</script>

<style scoped>
@import url("../../assets/pc/animation/animation.css");
.aboutUs {
  position: absolute;
  width: 100%;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  letter-spacing: 0.5vh;
  box-sizing: border-box;
  padding-top: 30vh;
}
.aboutUs_title1 {
  margin-left: 5vh;
  margin-bottom: 0vh;
  font-size: 5.5vh;
  animation: fade-in;
  animation-duration: 1s;
  -webkit-animation: fade-in 1s;
}
.aboutUs_title1 span {
  margin-left: 2vh;
}
.aboutUs_title2 {
  margin-left: 7vh;
  margin-bottom: 3.8vh;
  opacity: 0;
  background-position: 2.6vh 0vh;
  font-size: 2vh;
  background-repeat: no-repeat;
  background-image: url("../../assets/pc/img/aboutUs/title-2.png");
  animation: rightEaseInAnimate 3s ease 1;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}
@keyframes rightEaseInAnimate {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
.aboutUs_title_img {
  width: 20vh;
  height: 2px;
  animation: shake 5s;
  animation-name: shanke;
  animation-iteration-count: 1s;
  animation-fill-mode: forwards;
}
@keyframes shanke {
  0% {
    width: 0;
    background: white;
    opacity: 0.4;
  }
  50% {
    width: 45%;
    background: white;
    opacity: 0.4;
  }
  100% {
    width: 45%;
    background: white;
    opacity: 0.4;
  }
}
.aboutUs_content {
  display: flex;
  margin-left: 9vh;
  margin-top: 3vh;
  width: 120vh;
  font-size: 1.5vh;
  animation: fade-in;
  animation-duration: 3s;
  -webkit-animation: fade-in 3s;
}
.aboutUs_content_left {
  width: 13vh;
  box-sizing: border-box;
  font-size: 4vh;
  margin-left: -2vh;
}
.aboutUs_content_right {
  margin-top: 2.5vh;
}
</style>