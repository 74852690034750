<template>
  <div class="homePage">
    <div class="homePage_imgbox">
      <img
        class="el-carousel__item3"
        src="@/assets/m/img/Home page/homePage1-3.png"
      />
      <div class="el-carousel__item1">
        <span>游戏乐趣</span>
        <span>不止于此</span>
      </div>
      <div class="el-carousel__item2">
        <span class="el-carousel__item2_icon"></span>
        <span class="el-carousel__item2_span">More</span>
        <span class="el-carousel__item2_span">Than</span>
        <span class="el-carousel__item2_span">Just</span>
        <span class="el-carousel__item2_span">Games</span>
        <span class="el-carousel__item2_icon"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "homePage",
  data() {
    return {
      imgList: [
        { url: require("@/assets/m/img/Home page/homePage1.png"), id: 0 },
        { url: require("@/assets/m/img/Home page/homePage2.png"), id: 1 },
        { url: require("@/assets/m/img/Home page/homePage3.png"), id: 2 },
      ],
    };
  },
};
</script>

<style scoped>
@import url("../../assets/m/animation/animation.css");
.homePage {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
}
.homePage_imgbox {
  width: 50vh;
  height: 25vh;
  margin: auto;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.el-carousel__item1 {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-49%, -50%);
  font-size: 3vh;
  color: #1f436c;
  letter-spacing: 1.5vh;
  font-style: oblique;
  animation: fade-in;
  animation-duration: 3s;
  -webkit-animation: fade-in 3s;
}
.el-carousel__item1 span {
  margin-left: 1vh;
  margin-right: 1vh;
}
.el-carousel__item2 {
  width: 100%;
  text-align: center;
  color: #d5e7f2;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-style: oblique;
  font-size: 1.5vh;
  letter-spacing: 1vh;
  animation: fade-in;
  animation-duration: 5s;
  -webkit-animation: fade-in 5s;
}
.el-carousel__item2_span {
  margin-left: 0.9vh;
  margin-right: 0.9vh;
}
.el-carousel__item2_icon {
  display: inline-block;
  width: 1vh;
  height: 1vh;
  margin-right: 0.5vh;
  background: #d5e7f2;
}
.el-carousel__item3 {
  width: 30vh;
  position: absolute;
  left: 17vh;
  top: 7vh;
  animation: fade-in;
  animation-duration: 7s;
  -webkit-animation: fade-in 7s;
}
</style>
